export const prepareHeaderReferee = (data) => {
  let newData = { ...data };

  if (data?.stats && data?.stats?.fouls && data?.stats?.['red_cards'] && data?.stats?.['yellow_cards']) {
    newData.extraInfoAll = {
      "fouls":
        (data?.stats?.fouls["all_match"] || 0),
      "cards":
        ((data?.stats?.['red_cards']["all_match"] || 0) + (data?.stats?.['yellow_cards']["all_match"] || 0)).toFixed(2),
    };
  }

  if (data?.stats && data?.stats?.fouls) {
    newData.extraInfoFouls = {
      "first_half fouls":
        (data?.stats?.fouls["first_half"] || 0),
      "second_half fouls":
        (data?.stats?.fouls["second_half"] || 0),
    };
  }

  if (data?.stats && data?.stats?.['red_cards'] && data?.stats?.['yellow_cards']) {
    newData.extraInfoCards = {
      "first_half cards":
        ((data?.stats?.['red_cards']["first_half"] || 0) +
        (data?.stats?.['yellow_cards']["first_half"] || 0)).toFixed(2),
      "second_half cards":
        ((data?.stats?.['red_cards']["second_half"] || 0) +
        (data?.stats?.['yellow_cards']["second_half"] || 0)).toFixed(2),
    };
  }

  return newData;
};
