export const prepareTourSeasonTable = (data, type) => {
  const sumResult = {
    season: "All",
    matches: 0,
    yellow: 0,
    red: 0,
    penalty: 0,
    fouls: 0,
  }

  const tableResult = Object.entries(data).map(([key, value]) => {
    sumResult.matches += value.stats.yellow_cards.count
    sumResult.yellow += value.stats.yellow_cards.value
    sumResult.red += value.stats.red_cards.value
    sumResult.penalty += value.stats.penalties.value
    sumResult.fouls += value.stats.fouls.value

    return {
      season: value.name,
      matches: value.stats.yellow_cards.count,
      yellow: value.stats.yellow_cards.value,
      red: value.stats.red_cards.value,
      penalty: value.stats.penalties.value,
      fouls: value.stats.fouls.value,
    }
  })

  if (type === "avg") {
    sumResult.matches = (sumResult.matches / tableResult.length).toFixed(2)
    sumResult.yellow = (sumResult.yellow / tableResult.length).toFixed(2)
    sumResult.red = (sumResult.red / tableResult.length).toFixed(2)
    sumResult.penalty = (sumResult.penalty / tableResult.length).toFixed(2)
    sumResult.fouls = (sumResult.fouls / tableResult.length).toFixed(2)
  }

  return { table: tableResult, sums: sumResult }
}
