import "./RefereeBar.scss";

export const RefereeBar = ({ data }) => {
  return (
    <>
      {Object.keys(data || {}).length > 0 && (
        <div className="referee-bar">
          <p className="referee-bar__league">Referee stats</p>
          <p className="referee-bar__name">{data?.name}</p>
          <div className="referee-bar-stats">
            {Object.entries(data?.extraInfoAll || {}).map(([key, value]) => (
              <div
                className="league-bar-stats__item"
                key={key}
                style={{ display: "flex", height: 30 }}
              >
                {key}: <span>{value}</span>
              </div>
            ))}
            {Object.entries(data?.extraInfoCards || {}).map(([key, value]) => (
              <div
                className="league-bar-stats__item"
                key={key}
                style={{ display: "flex", height: 30 }}
              >
                {key}: <span>{value}</span>
              </div>
            ))}
            {Object.entries(data?.extraInfoFouls || {}).map(([key, value]) => (
              <div
                className="league-bar-stats__item"
                key={key}
                style={{ display: "flex", height: 30 }}
              >
                {key}: <span>{value}</span>
              </div>
            ))}
            {/* {Object.entries(data?.stats?.extraInfoCards || {}).map(
              ([key, value], index) => {
                // const arr = Object.entries(value || { "All match": 0 }).map(
                //   ([time, val], index) => (
                //     <p key={index} className="league-bar-stats__item">
                //       {time}: <span>{val}</span>
                //     </p>
                //   )
                // )
                <div key={index} style={{ display: "flex", height: 30 }}>
                  {key}: {value}
                </div>;
                // return (
                //   <div key={index} style={{ display: "flex", height: 30 }}>
                //     {key}: {value}
                //   </div>
                // );
              }
            )} */}
          </div>
        </div>
      )}
    </>
  );
};
