import { useEffect, useState } from "react";
import {
  getH2HFixtures,
  getOddsByTotals,
  getRefereeFixtures,
  getTeamFixtures,
  getTeamHeader,
  getRefereeHeader,
  getFixtureHeader,
  getRefereeSeason,
  getRefereeTournaments,
  getRefereeOthers,
} from "../http";
import { prepareFilters } from "../utils/prepareFilters";
import { prepareFixturesTable } from "../../../utils/prepareFixturesTable";
import { prepareTable } from "../../../utils/prepareTable";
import { prepareOddsTable } from "../../../utils/prepareOddsTable";
import { prepareTeamHeader } from "../../../utils/prepareTeamHeader";
import { prepareRefereeHeader } from "../../../utils/prepareRefereeHeader";
import { prepareRefereeSeasonTable } from "../utils/prepareRefereeSeasonTable";
import { prepareRefereeTournamentsTable } from "../utils/prepareRefereeTournamentsTable";
import { prepareOthers } from "../utils/prepareOthers";
import { verticalTable } from "../../../utils/verticalTable";
import { h2hResForTables } from "../../../utils/h2hResForTables";
import { refereeResFotTables } from "../../../utils/refereeResForTables";
import { getInfoAboutMatches } from "../../../utils/getInfoAboutMatches";
import { refereePrepareFilters } from "../utils/refereePrepareFilteres";
import { currentPrepareFilters } from "../utils/currentPrepareFixtures";
import { prepareHeaderReferee } from "../utils/prepareHeaderReferee";
import { prepareWithPrev } from "../utils/prepareWithPrev";
import { sleep } from "../../../utils/sleep";

export const usePrematchStats = (id) => {
  const [result, setResult] = useState();
  const [statistics, setStatistics] = useState();
  const [refereeBarInfo, setRefereeBarInfo] = useState();
  const [referee, setReferee] = useState();
  const [others, setOthers] = useState();
  const [filters, setFilters] = useState();
  const [homeFilters, setHomeFilters] = useState();
  const [awayFilters, setAwayFilters] = useState();
  const [h2hFilters, setH2hFilters] = useState();
  const [refereeFilters, setRefereeFilters] = useState();
  const [tableRefereeFixtures, setTableRefereeFixtures] = useState();
  const [tableHomeFixtures, setTableHomeFixtures] = useState();
  const [tableAwayFixtures, setTableAwayFixtures] = useState();
  const [tableHomeOdds, setTableHomeOdds] = useState();
  const [tableAwayOdds, setTableAwayOdds] = useState();
  const [tableH2h, setTableH2h] = useState();
  const [tableH2hOdds, setTableH2hOdds] = useState();
  const [tableRefereeOdds, setTableRefereeOdds] = useState();
  const [currentRefereeLeagues, setCurrentRefereeLeagues] = useState();
  const [refereeSeasonFilters, setRefereeSeasonFilters] = useState();
  const [refereeSeasonTable, setRefereeSeasonTable] = useState();
  const [refereeTournamentsFilters, setRefereeTournamentsFilters] = useState();
  const [refereeTournamentsTable, setRefereeTournamentsTable] = useState();
  const [timerange, setTimerange] = useState({});
  const [tableHomeManual, setTableHomeManual] = useState({
    table: [],
    sums: {},
  });
  const [tableAwayManual, setTableAwayManual] = useState({
    table: [],
    sums: {},
  });
  const [tableH2hManual, setTableH2hManual] = useState({ table: [], sums: {} });
  const [tableRefereeManual, setTableRefereeManual] = useState({
    table: [],
    sums: {},
  });
  const [textHome, setTextHome] = useState();
  const [textAway, setTextAway] = useState();
  const [homeLeaguesArray, setHomeLeaguesArray] = useState([]);
  const [awayLeaguesArray, setAwayLeaguesArray] = useState([]);
  const [filtersSeasons, setFiltersSeasons] = useState();
  const [curH2hLeagues, setCurH2hLeagues] = useState();
  const [loading, setLoading] = useState(false);
  const [homeFixturesStatus, setHomeFixturesStatus] = useState(null);
  const [awayFixturesStatus, setAwayFixturesStatus] = useState(null);
  const [h2hFixturesStatus, setH2hFixturesStatus] = useState(null);
  const [refereeFixturesStatus, setRefereeFixturesStatus] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const headerResponse = await getFixtureHeader(id);

      setResult(headerResponse.header);

      const homeTeamResponse = await getTeamHeader(
        headerResponse.header.home.id
      );

      const awayTeamResponse = await getTeamHeader(
        headerResponse.header.away.id
      );

      setHomeLeaguesArray(homeTeamResponse.params.leagues);
      setAwayLeaguesArray(awayTeamResponse.params.leagues);

      setCurH2hLeagues(headerResponse.h2h_params.leagues);

      setStatistics(homeTeamResponse);

      setRefereeBarInfo(prepareHeaderReferee(headerResponse.header.referee));

      const homeSeasons = {};
      for (const key of homeTeamResponse.params.seasons) {
        if (homeTeamResponse.params.fixtures.default.seasons.includes(key))
          homeSeasons[key] = true;
        else homeSeasons[key] = false;
      }

      const homeTimes = {};
      for (const key of homeTeamResponse.params.fixtures.stats.time) {
        if (typeof key === "string") {
          if (homeTeamResponse.params.fixtures.default.time === key)
            homeTimes[key] = true;
          else homeTimes[key] = false;
        }
      }

      const awaySeasons = {};
      for (const key of awayTeamResponse.params.seasons) {
        if (awayTeamResponse.params.fixtures.default.seasons.includes(key))
          awaySeasons[key] = true;
        else awaySeasons[key] = false;
      }

      const awayTimes = {};
      for (const key of awayTeamResponse.params.fixtures.stats.time) {
        if (typeof key === "string") {
          if (awayTeamResponse.params.fixtures.default.time === key)
            awayTimes[key] = true;
          else awayTimes[key] = false;
        }
      }

      const newHomeLeagues = prepareTeamHeader(homeTeamResponse).leagues;

      for (const league of homeTeamResponse.params.leagues) {
        for (const leag of homeTeamResponse.params.fixtures.default.leagues) {
          if (leag === league.id) newHomeLeagues[league.name] = true;
        }
      }

      const newAwayLeagues = prepareTeamHeader(awayTeamResponse).leagues;

      for (const league of awayTeamResponse.params.leagues) {
        for (const leag of awayTeamResponse.params.fixtures.default.leagues) {
          if (leag === league.id) newAwayLeagues[league.name] = true;
        }
      }

      const newH2hLeagues = {};

      for (const league of headerResponse.h2h_params.leagues) {
        for (const leag of headerResponse.h2h_params.default.leagues) {
          if (leag === league.id) newH2hLeagues[league.name] = true;
          else newH2hLeagues[league.name] = false;
        }
      }

      for (const league of homeTeamResponse.params.leagues) {
        for (const leag of headerResponse.h2h_params.leagues) {
          if (leag === league.id) newAwayLeagues[league.name] = true;
        }
      }

      setFilters({
        count: homeTeamResponse.params.fixtures.default.count,
        time: homeTimes,
        stat_type: homeTeamResponse.params.fixtures.default.stat_type,
      });

      setHomeFilters({
        leagues: newHomeLeagues,
        seasons: homeSeasons,
        place:
          homeTeamResponse.params.fixtures.default.place
            .charAt(0)
            .toUpperCase() +
          homeTeamResponse.params.fixtures.default.place.slice(1),
        under_active_coach:
          homeTeamResponse.params.fixtures.default.under_active_coach,
        against_teams: homeTeamResponse.params.fixtures.default.against_teams,
      });

      setAwayFilters({
        leagues: newAwayLeagues,
        seasons: awaySeasons,
        place:
          awayTeamResponse.params.fixtures.default.place
            .charAt(0)
            .toUpperCase() +
          awayTeamResponse.params.fixtures.default.place.slice(1),
        under_active_coach:
          awayTeamResponse.params.fixtures.default.under_active_coach,
        against_teams: awayTeamResponse.params.fixtures.default.against_teams,
      });

      setFiltersSeasons(headerResponse.h2h_params.default.seasons);

      setH2hFilters({
        count: headerResponse.h2h_params.default.count,
        time: {
          "All match": true,
          "1st half": false,
          "2nd half": false,
        },
        stat_type: headerResponse.h2h_params.default.stat_type,
        leagues: newH2hLeagues,
        seasons: headerResponse.h2h_params.seasons,
      });

      try {
        setLoading(true)
        await sleep(500)
        const homeFixturesResponse = await getTeamFixtures(
          homeTeamResponse.params.fixtures.default.stat_type,
          homeTeamResponse.params.fixtures.default.count,
          headerResponse.header.home.id,
          homeTeamResponse.params.fixtures.default.place.charAt(0).toUpperCase() +
            homeTeamResponse.params.fixtures.default.place.slice(1),
          homeTeamResponse.params.fixtures.default.under_active_coach,
          prepareFilters(
            {
              time: homeTimes,
              seasons: homeSeasons,
              leagues: newHomeLeagues,
              against_teams:
                homeTeamResponse.params.fixtures.default.against_teams,
            },
            homeTeamResponse.params.leagues
          )
        );

        const awayFixturesResponse = await getTeamFixtures(
          awayTeamResponse.params.fixtures.default.stat_type,
          awayTeamResponse.params.fixtures.default.count,
          headerResponse.header.away.id,
          awayTeamResponse.params.fixtures.default.place.charAt(0).toUpperCase() +
            awayTeamResponse.params.fixtures.default.place.slice(1),
          awayTeamResponse.params.fixtures.default.under_active_coach,
          prepareFilters(
            {
              time: awayTimes,
              seasons: awaySeasons,
              leagues: newAwayLeagues,
              against_teams:
                awayTeamResponse.params.fixtures.default.against_teams,
            },
            awayTeamResponse.params.leagues
          )
        );

        setTableHomeFixtures(
          prepareTable(homeFixturesResponse.data, headerResponse.header.home.name)
        );
        setHomeFixturesStatus(homeFixturesResponse.status)

        console.log(tableHomeFixtures)
  
        setTableAwayFixtures(
          prepareTable(awayFixturesResponse.data, headerResponse.header.away.name)
        );
        setAwayFixturesStatus(awayFixturesResponse.status)

      } catch (error) {
        if (error.response) {
          console.error("Status", error.response.status);
        } else if (error.request) {
          console.error("Error of request:", error.request);
        } else {
          console.error("Simple error is:", error);
        }
      } finally {
        setLoading(false)
      }

      try {
        setLoading(true)
        await sleep(500)
        const h2hResponse = await getH2HFixtures(
          headerResponse.h2h_params.default.stat_type,
          headerResponse.h2h_params.default.count,
          headerResponse.header.home.id,
          headerResponse.header.away.id,
          {
            time: headerResponse.h2h_params.default.time,
            seasons: headerResponse.h2h_params.default.seasons,
            leagues: headerResponse.h2h_params.default.leagues,
          }
        );
  
        setTableH2h(prepareFixturesTable(h2hResponse.data));
        setH2hFixturesStatus(h2hResponse.status)
      } catch (error) {
        if (error.response) {
          console.error("Status", error.response.status);
        } else if (error.request) {
          console.error("Error of request:", error.request);
        } else {
          console.error("Simle error is:", error);
        }
      } finally {
        setLoading(false)
      }

      const refereeHeaderResponse = await getRefereeHeader(
        headerResponse.header.referee.id
      );

      const refereeTimes = {};
      for (const key of ["All match", "1st half", "2nd half"]) {
        if (typeof key === "string") {
          if (refereeHeaderResponse.params.default.time === key)
            refereeTimes[key] = true;
          else refereeTimes[key] = false;
        }
      }

      const refereeSeasons = {};
      for (const key of refereeHeaderResponse.params.seasons) {
        if (refereeHeaderResponse.params.default.seasons.includes(key))
          refereeSeasons[key] = true;
        else refereeSeasons[key] = false;
      }

      const refereeLeagues = {};

      for (const league of refereeHeaderResponse.params.leagues) {
        refereeLeagues[league?.name] = false;
        for (const leag of refereeHeaderResponse.params.default.leagues) {
          if (leag === league?.id) {
            refereeLeagues[league?.name] = true;
          } else refereeLeagues[league?.name] = false;
        }
      }
      setCurrentRefereeLeagues(refereeHeaderResponse.params.leagues);

      setReferee(prepareRefereeHeader(refereeHeaderResponse));
      setRefereeFilters({
        count: refereeHeaderResponse.params.default.count,
        time: refereeTimes,
        stat_type: refereeHeaderResponse.params.default.stat_type,
        only_with: refereeHeaderResponse.params.default.only_with,
        leagues: refereeLeagues,
        seasons: refereeSeasons,
        against_teams: refereeHeaderResponse.params.default.against_teams,
      });

      try {
        setLoading(true)
        await sleep(500)
        const refereeFixturesResponse = await getRefereeFixtures(
          refereeHeaderResponse.params.default.stat_type,
          refereeHeaderResponse.params.default.count,
          refereeHeaderResponse.referee.id,
          refereeHeaderResponse.params.default.only_with,
          refereePrepareFilters(
            {
              time: refereeTimes,
              leagues: refereeLeagues,
              seasons: refereeSeasons,
              against_teams: refereeHeaderResponse.params.default.against_teams,
            },
            refereeHeaderResponse.params.leagues
          )
        );
        
        setTableRefereeFixtures(prepareFixturesTable(refereeFixturesResponse.data));
        setRefereeFixturesStatus(refereeFixturesResponse.status)
      } catch (error) {
        if (error.response) {
          console.error("Status", error.response.status);
        } else if (error.request) {
          console.error("Error of request:", error.request);
        } else {
          console.error("Simle error is:", error);
        }
      } finally {
        setLoading(false)
      }

      const refereeFixturesResponse = await getRefereeFixtures(
        refereeHeaderResponse.params.default.stat_type,
        refereeHeaderResponse.params.default.count,
        refereeHeaderResponse.referee.id,
        refereeHeaderResponse.params.default.only_with,
        refereePrepareFilters(
          {
            time: refereeTimes,
            leagues: refereeLeagues,
            seasons: refereeSeasons,
            against_teams: refereeHeaderResponse.params.default.against_teams,
          },
          refereeHeaderResponse.params.leagues
        )
      );
      setTableRefereeFixtures(prepareFixturesTable(refereeFixturesResponse.data));
      setRefereeFixturesStatus(refereeFixturesResponse.status)

      const refeeSeasonResponse = await getRefereeSeason(
        headerResponse.header.referee.id,
        refereeHeaderResponse.last_season
      );
      setRefereeSeasonFilters({
        season_name: refereeHeaderResponse.last_season,
        category: "Totals",
      });
      setRefereeSeasonTable(prepareRefereeSeasonTable(refeeSeasonResponse));

      const refereeTournamentsResponse = await getRefereeTournaments(
        headerResponse.header.referee.id,
        refereeHeaderResponse.last_league.id
      );
      setRefereeTournamentsFilters({
        tournament_name:
          refereeHeaderResponse.params.default.leagues[0]?.name ||
          refereeHeaderResponse.params.leagues[0]?.name,
        category: "Totals",
      });
      setRefereeTournamentsTable(
        prepareRefereeTournamentsTable(refereeTournamentsResponse)
      );

      const refereeOthersResponse = await getRefereeOthers(
        headerResponse.header.referee.id,
        refereeHeaderResponse.last_league.id
      );
      setOthers(prepareOthers(refereeOthersResponse.results));
    };
    fetch();
  }, [id]);

  const setRefereeTournaments = async (activeKey) => {
    setRefereeTournamentsFilters((prev) => ({
      ...prev,
      tournament_name: activeKey,
    }));

    let newId = 0;
    for (const league of currentRefereeLeagues) {
      if (league?.name === activeKey) newId = league?.id;
    }

    const response = await getRefereeTournaments(result.referee.id, newId);
    setRefereeTournamentsTable(prepareRefereeTournamentsTable(response));
  };

  const setRefereeTournamentsCategory = (activeKey) => {
    setRefereeTournamentsFilters((prev) => ({ ...prev, category: activeKey }));
  };

  const setRefereeSeasonSeasons = async (activeKey) => {
    setRefereeSeasonFilters((prev) => ({ ...prev, season_name: activeKey }));

    const response = await getRefereeSeason(result.referee.id, activeKey);
    setRefereeSeasonTable(prepareRefereeSeasonTable(response));
  };

  const setRefereeSeasonCategory = (activeKey) => {
    setRefereeSeasonFilters((prev) => ({ ...prev, category: activeKey }));
  };

  const setTime = (activeKey) => {
    const time = filters.time;
    if (typeof activeKey === "object") setTimerange(activeKey);
    else setTimerange({});
    var newTime = {};
    for (const [key] of Object.entries(time)) {
      newTime[key] = activeKey === key ? true : false;
    }
    setFilters((prev) => ({ ...prev, time: newTime }));
  };

  const setStatType = (activeKey) => {
    setFilters((prev) => ({ ...prev, stat_type: activeKey }));
  };

  const setCount = (activeKey) => {
    setFilters((prev) => ({
      ...prev,
      count: activeKey,
    }));
  };

  const setRefereeTime = (activeKey) => {
    const time = refereeFilters.time;
    if (typeof activeKey === "object") setTimerange(activeKey);
    else setTimerange({});
    var newTime = {};
    for (const [key] of Object.entries(time)) {
      newTime[key] = activeKey === key ? true : false;
    }
    setRefereeFilters((prev) => ({ ...prev, time: newTime }));
  };

  const setRefereeStatType = (activeKey) => {
    setRefereeFilters((prev) => ({ ...prev, stat_type: activeKey }));
  };

  const setRefereeCount = (activeKey) => {
    setRefereeFilters((prev) => ({
      ...prev,
      count: activeKey,
    }));
  };

  const setRefereeSeasons = (activeSeason) => {
    const keyArray = activeSeason.split("/");
    var newKey =
      keyArray.length < 2 ? activeSeason : `${keyArray[0]}/${keyArray[1]}`;
    const season = refereeFilters?.seasons;

    var newSeason = {};
    let count = 1;
    for (const [key] of Object.entries(season)) {
      newSeason[key] =
        (key === newKey && !season[key]) || (season[key] && newKey !== key);
      if (newSeason[key]) count++;
    }
    if (count > 1)
      setRefereeFilters((prev) => ({ ...prev, seasons: newSeason }));
  };

  const setAllRefereeSeasons = (selectAll, val) => {
    const season = refereeFilters?.seasons;

    var newSeason = {};
    for (const [key] of Object.entries(season)) {
      newSeason[key] = selectAll;
    }
    if (val) newSeason[val] = true;
    setRefereeFilters((prev) => ({ ...prev, seasons: newSeason }));
  };

  const setRefereeLeagues = (activeLeague) => {
    const league = refereeFilters?.leagues;

    var newLeague = {};
    let count = 1;
    for (const [key] of Object.entries(league)) {
      newLeague[key] =
        (key === activeLeague && !league[key]) ||
        (league[key] && activeLeague !== key);
      if (newLeague[key]) count++;
    }
    if (count > 1)
      setRefereeFilters((prev) => ({ ...prev, leagues: newLeague }));
  };

  const setAllRefereeLeagues = (selectAll, val) => {
    const league = refereeFilters?.leagues;

    var newLeague = {};
    for (const [key] of Object.entries(league)) {
      newLeague[key] = selectAll;
    }
    if (val) newLeague[val] = true;
    setRefereeFilters((prev) => ({ ...prev, leagues: newLeague }));
  };

  const setRefereeRanked = (activeKey) => {
    setRefereeFilters((prev) => ({
      ...prev,
      against_teams: activeKey,
    }));
  };

  const setRefereeOnlyWith = (activeKey) => {
    setRefereeFilters((prev) => ({
      ...prev,
      only_with:
        activeKey === "All"
          ? activeKey.charAt(0).toLowerCase() + activeKey.slice(1)
          : activeKey.charAt(0).toUpperCase() + activeKey.slice(1),
    }));
  };

  const setHomeCoach = (activeKey) => {
    setHomeFilters((prev) => ({
      ...prev,
      under_active_coach: activeKey === "All" ? false : true,
    }));
  };

  const setAwayCoach = (activeKey) => {
    setAwayFilters((prev) => ({
      ...prev,
      under_active_coach: activeKey === "All" ? false : true,
    }));
  };

  const setH2hTime = (activeKey) => {
    const time = h2hFilters.time;
    if (typeof activeKey === "object") setTimerange(activeKey);
    else setTimerange({});
    var newTime = {};
    for (const [key] of Object.entries(time)) {
      newTime[key] = activeKey === key ? true : false;
    }
    setH2hFilters((prev) => ({ ...prev, time: newTime }));
  };

  const setH2hStatType = (activeKey) => {
    setH2hFilters((prev) => ({ ...prev, stat_type: activeKey }));
  };

  const setH2hCount = (activeKey) => {
    setH2hFilters((prev) => ({
      ...prev,
      count: activeKey,
    }));
  };

  const setH2hSeasons = (activeSeason) => {
    const keyArray = activeSeason.split("/");
    var newKey =
      keyArray.length < 2 ? activeSeason : `${keyArray[0]}/${keyArray[1]}`;
    const season = h2hFilters?.seasons;

    var newSeason = {};
    let count = 1;
    for (const [key] of Object.entries(season)) {
      newSeason[key] =
        (key === newKey && !season[key]) || (season[key] && newKey !== key);
      if (newSeason[key]) count++;
    }
    if (count > 1) setH2hFilters((prev) => ({ ...prev, seasons: newSeason }));
  };

  const setH2hSeason = (activeFilter) => {
    if (filtersSeasons.length === h2hFilters.seasons.length) {
      setFiltersSeasons([activeFilter]);
    } else {
      if (filtersSeasons.includes(activeFilter)) {
        setFiltersSeasons(
          filtersSeasons.filter((elem) => elem !== activeFilter)
        );
      } else {
        setFiltersSeasons(filtersSeasons.concat(activeFilter));
      }
    }
  };

  const setH2hAllSeasons = () => {
    setFiltersSeasons(h2hFilters.seasons);
  };

  const setAllH2hSeasons = (selectAll, val) => {
    const season = h2hFilters?.seasons;

    var newSeason = {};
    for (const [key] of Object.entries(season)) {
      newSeason[key] = selectAll;
    }
    if (val) newSeason[val] = true;
    setH2hFilters((prev) => ({ ...prev, seasons: newSeason }));
  };

  const setH2hLeagues = (activeLeague) => {
    const league = h2hFilters?.leagues;

    var newLeague = {};
    let count = 1;
    for (const [key] of Object.entries(league)) {
      newLeague[key] =
        (key === activeLeague && !league[key]) ||
        (league[key] && activeLeague !== key);
      if (newLeague[key]) count++;
    }
    if (count > 1) setH2hFilters((prev) => ({ ...prev, leagues: newLeague }));
  };

  const setAllH2hLeagues = (selectAll, val) => {
    const league = h2hFilters?.leagues;

    var newLeague = {};
    for (const [key] of Object.entries(league)) {
      newLeague[key] = selectAll;
    }
    if (val) newLeague[val] = true;
    setH2hFilters((prev) => ({ ...prev, leagues: newLeague }));
  };

  const setHomePlace = (activeKey) => {
    setHomeFilters((prev) => ({
      ...prev,
      place: activeKey.charAt(0).toUpperCase() + activeKey.slice(1),
    }));
  };

  const setAwayPlace = (activeKey) => {
    setAwayFilters((prev) => ({
      ...prev,
      place: activeKey.charAt(0).toUpperCase() + activeKey.slice(1),
    }));
  };

  const setHomeSeasons = (activeSeason) => {
    const keyArray = activeSeason.split("/");
    var newKey =
      keyArray.length < 2 ? activeSeason : `${keyArray[0]}/${keyArray[1]}`;
    const season = homeFilters?.seasons;

    var newSeason = {};
    let count = 1;
    for (const [key] of Object.entries(season)) {
      newSeason[key] =
        (key === newKey && !season[key]) || (season[key] && newKey !== key);
      if (newSeason[key]) count++;
    }
    if (count > 1) setHomeFilters((prev) => ({ ...prev, seasons: newSeason }));
  };

  const setAwaySeasons = (activeSeason) => {
    const keyArray = activeSeason.split("/");
    var newKey =
      keyArray.length < 2 ? activeSeason : `${keyArray[0]}/${keyArray[1]}`;
    const season = awayFilters?.seasons;

    var newSeason = {};
    let count = 1;
    for (const [key] of Object.entries(season)) {
      newSeason[key] =
        (key === newKey && !season[key]) || (season[key] && newKey !== key);
      if (newSeason[key]) count++;
    }
    if (count > 1) setAwayFilters((prev) => ({ ...prev, seasons: newSeason }));
  };

  const setAllHomeSeasons = (selectAll, val) => {
    const season = homeFilters?.seasons;

    var newSeason = {};
    for (const [key] of Object.entries(season)) {
      newSeason[key] = selectAll;
    }
    if (val) newSeason[val] = true;
    setHomeFilters((prev) => ({ ...prev, seasons: newSeason }));
  };

  const setAllAwaySeasons = (selectAll, val) => {
    const season = awayFilters?.seasons;

    var newSeason = {};
    for (const [key] of Object.entries(season)) {
      newSeason[key] = selectAll;
    }
    if (val) newSeason[val] = true;
    setAwayFilters((prev) => ({ ...prev, seasons: newSeason }));
  };

  const setHomeTeamsRanked = (activeKey) => {
    setHomeFilters((prev) => ({
      ...prev,
      against_teams: activeKey,
    }));
  };

  const setAwayTeamsRanked = (activeKey) => {
    setAwayFilters((prev) => ({
      ...prev,
      against_teams: activeKey,
    }));
  };

  const setHomeLeagues = (activeLeague) => {
    const league = homeFilters?.leagues;

    var newLeague = {};
    let count = 1;
    for (const [key] of Object.entries(league)) {
      newLeague[key] =
        (key === activeLeague && !league[key]) ||
        (league[key] && activeLeague !== key);
      if (newLeague[key]) count++;
    }
    if (count > 1) setHomeFilters((prev) => ({ ...prev, leagues: newLeague }));
  };

  const setAwayLeagues = (activeLeague) => {
    const league = awayFilters?.leagues;

    var newLeague = {};
    let count = 1;
    for (const [key] of Object.entries(league)) {
      newLeague[key] =
        (key === activeLeague && !league[key]) ||
        (league[key] && activeLeague !== key);
      if (newLeague[key]) count++;
    }
    if (count > 1) setAwayFilters((prev) => ({ ...prev, leagues: newLeague }));
  };

  const setAllHomeLeagues = (selectAll, val) => {
    const league = homeFilters?.leagues;

    var newLeague = {};
    for (const [key] of Object.entries(league)) {
      newLeague[key] = selectAll;
    }
    if (val) newLeague[val] = true;
    setHomeFilters((prev) => ({ ...prev, leagues: newLeague }));
  };

  const setAllAwayLeagues = (selectAll, val) => {
    const league = awayFilters?.leagues;

    var newLeague = {};
    for (const [key] of Object.entries(league)) {
      newLeague[key] = selectAll;
    }
    if (val) newLeague[val] = true;
    setAwayFilters((prev) => ({ ...prev, leagues: newLeague }));
  };

  const setRefereeTableTotals = async (activeArr) => {
    if (activeArr?.length > 0) {
      const oddsResponse = await getOddsByTotals(activeArr);
      setTableRefereeOdds(prepareOddsTable(oddsResponse));
    } else
      setTableRefereeOdds({
        table: [{ t1: [] }, { t2: [] }, { t: [] }, { f1: [] }, { f2: [] }],
      });
  };

  const setRefereeTableTotalsManual = (activeArr) => {
    if (activeArr?.length) {
      setTableRefereeOdds({
        table: [
          { t1: overUnderTable(activeArr) },
          {
            t2: secondAndThirdOverUnderTable(
              refereeResFotTables(activeArr).t1Ot1U
            ),
          },
          {
            t: secondAndThirdOverUnderTable(
              refereeResFotTables(activeArr).t2Ot2U
            ),
          },
          { f1: fourthAndFifthTable(refereeResFotTables(activeArr).h1) },
          { f2: fourthAndFifthTable(refereeResFotTables(activeArr).h2) },
        ],
        sums: {
          home_wins: refereeResFotTables(activeArr).winDrawWinTable.home_wins,
          draws: refereeResFotTables(activeArr).winDrawWinTable.draws,
          away_wins: refereeResFotTables(activeArr).winDrawWinTable.away_wins,
        },
      });

      setTableRefereeManual({
        table: [],
        sums: refereeResFotTables(activeArr).verticalTable,
      });
    } else {
      setTableRefereeOdds({
        table: [{ t1: [] }, { t2: [] }, { t: [] }, { f1: [] }, { f2: [] }],
      });
      setTableRefereeManual({ table: [], sums: {} });
    }
  };

  const setH2hTableTotals = async (activeArr) => {
    if (activeArr?.length > 0) {
      const oddsResponse = await getOddsByTotals(activeArr);
      setTableH2hOdds(prepareOddsTable(oddsResponse));
    } else
      setTableH2hOdds({
        table: [{ t1: [] }, { t2: [] }, { t: [] }, { f1: [] }, { f2: [] }],
      });
  };

  const setH2hTableTotalsManual = (activeArr) => {
    if (activeArr?.length) {
      setTableH2hOdds({
        table: [
          { t1: overUnderTable(activeArr) },
          {
            t2: secondAndThirdOverUnderTable(h2hResForTables(activeArr).t1Ot1U),
          },
          {
            t: secondAndThirdOverUnderTable(h2hResForTables(activeArr).t2Ot2U),
          },
          { f1: fourthAndFifthTable(h2hResForTables(activeArr).h1) },
          { f2: fourthAndFifthTable(h2hResForTables(activeArr).h2) },
        ],
        sums: {
          home_wins: h2hResForTables(activeArr).winDrawWin.win1,
          draws: h2hResForTables(activeArr).winDrawWin.draw,
          away_wins: h2hResForTables(activeArr).winDrawWin.win2,
        },
      });

      setTableH2hManual({
        table: [],
        sums: h2hResForTables(activeArr).verticalTable,
      });
    } else {
      setTableH2hOdds({
        table: [{ t1: [] }, { t2: [] }, { t: [] }, { f1: [] }, { f2: [] }],
      });
      setTableH2hManual({ table: [], sums: {} });
    }
  };

  // функция для расчета over/under таблицы снизу
  const overUnderTable = (arr) => {
    const amountRows = Math.max.apply(
      Math,
      arr.map((i) => i.t)
    );
    return Array.from({ length: amountRows + 1 }, (_, i) => {
      const overValue = arr.filter((el) => el.t >= i + 1).length;
      return {
        total: `${i + 0.5}`,
        over: `${overValue}/${arr.length}`,
        under: `${arr.length - overValue}/${arr.length}`,
      };
    });
  };

  // функция для расчета T10/T1U и T2O/T2U таблиц снизу
  const secondAndThirdOverUnderTable = (arr) => {
    const amountRows = Math.max.apply(Math, arr);
    return Array.from({ length: amountRows + 1 }, (_, i) => {
      const overValue = arr.filter((el) => el >= i + 1).length;
      return {
        total: `${i + 0.5}`,
        over: `${overValue}/${arr.length}`,
        under: `${arr.length - overValue}/${arr.length}`,
      };
    });
  };

  // функция для расчета H1 и H2 таблиц снизу
  const fourthAndFifthTable = (arr) => {
    let negativeMax = -1000;
    let positiveMax = -1000;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] > arr[i][1]) {
        if (arr[i][0] - arr[i][1] > negativeMax) {
          negativeMax = arr[i][0] - arr[i][1];
        }
      } else {
        if (arr[i][1] - arr[i][0] > positiveMax) {
          positiveMax = arr[i][1] - arr[i][0];
        }
      }
    }
    let res1 = Array.from({ length: negativeMax + 1 }, (_, i) => {
      const overValue = arr.filter((el) => el[0] - el[1] > i).length;
      return { total: `${-0.5 - i}`, handicap: `${overValue}/${arr.length}` };
    });
    let res2 = Array.from({ length: positiveMax + 1 }, (_, i) => {
      const overValue = arr.filter((el) => el[0] - el[1] >= -i).length;
      return { total: `${i + 0.5}`, handicap: `${overValue}/${arr.length}` };
    });
    let res = res1.concat(res2);

    return res;
  };

  // Функция заменяющая post-запрос для левой таблицы (Pre-match Statistics)
  const setHomeTableTotalsManual = (activeArr) => {
    if (activeArr?.length) {
      setTextHome(getInfoAboutMatches(activeArr).home);
      // изменение информации для таблиц снизу
      setTableHomeOdds({
        table: [
          { t1: secondAndThirdOverUnderTable(verticalTable(activeArr).t1Ot1U) },
          { t2: secondAndThirdOverUnderTable(verticalTable(activeArr).t2Ot2U) },
          { t: overUnderTable(activeArr) },
          { f1: fourthAndFifthTable(verticalTable(activeArr).h1) },
          { f2: fourthAndFifthTable(verticalTable(activeArr).h2) },
        ],
        sums: {
          home_wins: verticalTable(activeArr).winDrawLoseTable.w,
          draws: verticalTable(activeArr).winDrawLoseTable.d,
          away_wins: verticalTable(activeArr).winDrawLoseTable.l,
        },
      });

      // изменение информации для вертикальной таблицы сверху
      setTableHomeManual({
        table: [],
        sums: verticalTable(activeArr).verticalTable,
      });
    } else {
      setTableHomeOdds({
        table: [{ t1: [] }, { t2: [] }, { t: [] }, { f1: [] }, { f2: [] }],
      });
      setTableHomeManual({ table: [], sums: {} });
      setTextHome(getInfoAboutMatches([]).home);
    }
  };

  // Функция заменяющая post-запрос для правой таблицы (Pre-match Statistics)
  const setAwayTableTotalsManual = (activeArr) => {
    if (activeArr?.length) {
      setTextAway(getInfoAboutMatches(activeArr).away);
      // изменение информации для таблиц снизу
      setTableAwayOdds({
        table: [
          { t1: secondAndThirdOverUnderTable(verticalTable(activeArr).t1Ot1U) },
          { t2: secondAndThirdOverUnderTable(verticalTable(activeArr).t2Ot2U) },
          { t: overUnderTable(activeArr) },
          { f1: fourthAndFifthTable(verticalTable(activeArr).h1) },
          { f2: fourthAndFifthTable(verticalTable(activeArr).h2) },
        ],
        sums: {
          home_wins: verticalTable(activeArr).winDrawLoseTable.w,
          draws: verticalTable(activeArr).winDrawLoseTable.d,
          away_wins: verticalTable(activeArr).winDrawLoseTable.l,
        },
      });

      // изменение информации для вертикальной таблицы сверху
      setTableAwayManual({
        table: [],
        sums: verticalTable(activeArr).verticalTable,
      });
    } else {
      setTableAwayOdds({
        table: [{ t1: [] }, { t2: [] }, { t: [] }, { f1: [] }, { f2: [] }],
      });
      setTableAwayManual({ table: [], sums: {} });
      setTextAway(getInfoAboutMatches([]).away);
    }
  };

  // замененная функция на setHomeTableTotalsManual
  const setHomeTableTotals = async (activeArr) => {
    if (activeArr?.length > 0) {
      const oddsResponse = await getOddsByTotals(activeArr);
      setTableHomeOdds(prepareOddsTable(oddsResponse));
    } else
      setTableHomeOdds({
        table: [{ t1: [] }, { t2: [] }, { t: [] }, { f1: [] }, { f2: [] }],
      });
  };

  // замененная функция на setAwayTableTotalsManual
  const setAwayTableTotals = async (activeArr) => {
    if (activeArr?.length > 0) {
      const oddsResponse = await getOddsByTotals(activeArr);
      setTableAwayOdds(prepareOddsTable(oddsResponse));
    } else
      setTableAwayOdds({
        table: [{ t1: [] }, { t2: [] }, { t: [] }, { f1: [] }, { f2: [] }],
      });
  };

  const onH2hTeamApplyClick = async () => {
    if (Object.keys(timerange).length === 0) {
      try {
        setLoading(true);
        await sleep(500)
        const h2hFixturesResponse = await getH2HFixtures(
          h2hFilters.stat_type,
          h2hFilters.count,
          result.home.id,
          result.away.id,
          prepareWithPrev(
            {
              time: h2hFilters.time,
              seasons: filtersSeasons,
              leagues: h2hFilters.leagues,
            },
            curH2hLeagues
          )
        );

        setTableH2h(prepareFixturesTable(h2hFixturesResponse.data));
        setH2hFixturesStatus(h2hFixturesResponse.status)

      } catch (error) {
        if (error.response) {
          console.error("Status", error.response.status);
        } else if (error.request) {
          console.error("Error of request:", error.request);
        } else {
          console.error("Simle error is:", error);
        }
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        await sleep(500)
        const h2hFixturesResponse = await getH2HFixtures(
          h2hFilters.stat_type,
          h2hFilters.count,
          result.home.id,
          result.away.id,
          prepareWithPrev(
            {
              time: timerange,
              seasons: filtersSeasons,
              leagues: h2hFilters.leagues,
            },
            curH2hLeagues
          )
        );

        setTableH2h(prepareFixturesTable(h2hFixturesResponse.data));
        setH2hFixturesStatus(h2hFixturesResponse.status)
      } catch (error) {
        if (error.response) {
          console.error("Status", error.response.status);
        } else if (error.request) {
          console.error("Error of request:", error.request);
        } else {
          console.error("Simle error is:", error);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const onBothTeamsApplyClick = async () => {
    if (Object.keys(timerange).length === 0) {
      try {
        setLoading(true);
        await sleep(500);
        const homeFixturesResponse = await getTeamFixtures(
          filters.stat_type,
          filters.count,
          result.home.id,
          homeFilters.place,
          homeFilters.under_active_coach,
          currentPrepareFilters(
            { ...filters, ...homeFilters },
            homeLeaguesArray
          ).ans.body
        );

        const awayFixturesResponse = await getTeamFixtures(
          filters.stat_type,
          filters.count,
          result.away.id,
          awayFilters.place,
          awayFilters.under_active_coach,
          currentPrepareFilters(
            { ...filters, ...awayFilters },
            awayLeaguesArray
          ).ans.body
        );

        setTableHomeFixtures(prepareTable(homeFixturesResponse.data));
        setHomeFixturesStatus(homeFixturesResponse.status)

        setTableAwayFixtures(prepareTable(awayFixturesResponse.data));
        setAwayFixturesStatus(awayFixturesResponse.status)

      } catch (error) {
        if (error.response) {
          console.error("Status", error.response.status);
        } else if (error.request) {
          console.error("Error of request:", error.request);
        } else {
          console.error("Simle error is:", error);
        }
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        await sleep(500);
        const homeFixturesResponse = await getTeamFixtures(
          filters.stat_type,
          filters.count,
          result.home.id,
          homeFilters.place,
          homeFilters.under_active_coach,
          currentPrepareFilters(
            { ...filters, ...homeFilters, time: timerange },
            homeLeaguesArray
          ).ans.body
        );
        const awayFixturesResponse = await getTeamFixtures(
          filters.stat_type,
          filters.count,
          result.away.id,
          awayFilters.place,
          awayFilters.under_active_coach,
          currentPrepareFilters(
            { ...filters, ...awayFilters, time: timerange },
            awayLeaguesArray
          ).ans.body
        );

        setTableHomeFixtures(prepareTable(homeFixturesResponse.data));
        setHomeFixturesStatus(homeFixturesResponse.status)

        setTableAwayFixtures(prepareTable(awayFixturesResponse.data));
        setAwayFixturesStatus(awayFixturesResponse.status)

      } catch (error) {
        if (error.response) {
          console.error("Status", error.response.status);
        } else if (error.request) {
          console.error("Error of request:", error.request);
        } else {
          console.error("Simle error is:", error);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const onRefereeApplyClick = async () => {
    if (Object.keys(timerange).length === 0) {
      try {
        setLoading(true);
        await sleep(500);
        const refereeFixturesResponse = await getRefereeFixtures(
          refereeFilters.stat_type,
          refereeFilters.count,
          result.referee.id,
          refereeFilters.only_with,
          refereePrepareFilters(
            {
              time: refereeFilters.time,
              leagues: refereeFilters.leagues,
              seasons: refereeFilters.seasons,
              against_teams: refereeFilters.against_teams,
            },
            currentRefereeLeagues
          )
        );

        setTableRefereeFixtures(prepareFixturesTable(refereeFixturesResponse.data));
        setRefereeFixturesStatus(refereeFixturesResponse.status)
      } catch (error) {
        if (error.response) {
          console.error("Status", error.response.status);
        } else if (error.request) {
          console.error("Error of request:", error.request);
        } else {
          console.error("Simle error is:", error);
        }
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        await sleep(500);
        const refereeFixturesResponse = await getRefereeFixtures(
          refereeFilters.stat_type,
          refereeFilters.count,
          result.referee.id,
          refereeFilters.only_with,
          refereePrepareFilters(
            { ...refereeFilters, time: timerange },
            currentRefereeLeagues
          )
        );

        setTableRefereeFixtures(prepareFixturesTable(refereeFixturesResponse.data));
        setRefereeFixturesStatus(refereeFixturesResponse.status)

      } catch (error) {
        if (error.response) {
          console.error("Status", error.response.status);
        } else if (error.request) {
          console.error("Error of request:", error.request);
        } else {
          console.error("Simle error is:", error);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return {
    result,
    statistics,
    referee,
    others,
    refereeBarInfo,
    filters,
    h2hFilters,
    refereeFilters,
    refereeSeasonFilters,
    refereeSeasonTable,
    refereeTournamentsFilters,
    refereeTournamentsTable,
    tableRefereeFixtures,
    tableHomeFixtures,
    tableAwayFixtures,
    tableHomeOdds,
    tableAwayOdds,
    tableH2hOdds,
    tableRefereeOdds,
    tableH2h,
    homeFilters,
    awayFilters,
    timerange,
    tableHomeManual,
    tableAwayManual,
    tableH2hManual,
    tableRefereeManual,
    textHome,
    textAway,
    filtersSeasons,
    loading,
    homeFixturesStatus,
    awayFixturesStatus,
    h2hFixturesStatus,
    refereeFixturesStatus,
    setTime,
    setStatType,
    setCount,
    setRefereeTime,
    setRefereeStatType,
    setRefereeCount,
    setRefereeSeasons,
    setAllRefereeSeasons,
    setAllRefereeLeagues,
    setRefereeLeagues,
    setRefereeRanked,
    setRefereeOnlyWith,
    setRefereeSeasonSeasons,
    setRefereeSeasonCategory,
    setRefereeTournaments,
    setRefereeTournamentsCategory,
    setHomePlace,
    setAwayPlace,
    setHomeSeasons,
    setAwaySeasons,
    setAllHomeSeasons,
    setAllAwaySeasons,
    setHomeTeamsRanked,
    setAwayTeamsRanked,
    setHomeLeagues,
    setAwayLeagues,
    setAllHomeLeagues,
    setAllAwayLeagues,
    setHomeTableTotals,
    setHomeTableTotalsManual,
    setAwayTableTotalsManual,
    setAwayTableTotals,
    setRefereeTableTotals,
    setRefereeTableTotalsManual,
    setH2hTime,
    setH2hStatType,
    setH2hSeasons,
    setAllH2hSeasons,
    setH2hCount,
    setAllH2hLeagues,
    setH2hLeagues,
    setH2hTableTotals,
    setH2hTableTotalsManual,
    setHomeCoach,
    setAwayCoach,
    onBothTeamsApplyClick,
    onH2hTeamApplyClick,
    onRefereeApplyClick,
    setH2hAllSeasons,
    setH2hSeason,
  };
};
