import { Link } from "react-router-dom";
import { Tooltip } from "../Tooltip/Tooltip";

const countTotalPercentage = (a, b) => {
  return (a / b) * 100;
};

export const TableRow = ({
  summary,
  item,
  colsWidth,
  handleClick,
  id,
  redCards = [],
  isChecked,
  isReferee = false,
  summaried = false,
  withCheckbox = false,
  showWinner = false,
  showGreen = false,
  teamName,
  teamId,
  nextItem,
}) => {
  let newBg1 = teamName === "both" ? "both" : "";
  let newBg2 = teamName === "both" ? "both" : "";
  let rowBg = "";

  let bgForTotal1 = "";
  let bgForTotal2 = "";

  let bgForTotal1WhenBoth = "";
  let bgForTotal2WhenBoth = "";

  let standingBg = "";

  (function getNewBg() {
    if (showWinner) {
      if (teamName === item.home) {
        if (item.t1 > item.t2) newBg1 = "rgba(151, 206, 167, 0.40)";
        else if (item.t1 < item.t2) newBg1 = "rgba(255, 95, 95, 0.40)";
        else newBg1 = "rgba(255, 192, 71, 0.40)";
      } else if (teamName === item.away) {
        if (item.t1 > item.t2) newBg1 = "rgba(255, 95, 95, 0.40)";
        else if (item.t1 < item.t2) newBg1 = "rgba(151, 206, 167, 0.40)";
        else newBg1 = "rgba(255, 192, 71, 0.40)";
      } else {
        if (item.t1 > item.t2) {
          newBg1 = "rgba(151, 206, 167, 0.40)";
          newBg2 = "rgba(255, 95, 95, 0.40)";
        } else if (item.t1 < item.t2) {
          newBg1 = "rgba(255, 95, 95, 0.40)";
          newBg2 = "rgba(151, 206, 167, 0.40)";
        } else {
          newBg1 = "rgba(255, 192, 71, 0.40)";
          newBg2 = "rgba(255, 192, 71, 0.40)";
        }
      }
    }
  })();
  (function getRowBg() {
    if (isReferee) {
      if (item.matches < 4) {
        rowBg = "rgb(235, 235, 235)";
      } else {
        if (Number(summary) < Number(item.total)) {
          if (
            countTotalPercentage(Number(item.total), Number(summary)) - 100 <=
            5
          )
            rowBg = "rgb(249, 224, 175)";
          else if (
            countTotalPercentage(Number(item.total), Number(summary)) - 100 <=
            15
          )
            rowBg = "rgb(255, 235, 235)";
          else if (
            countTotalPercentage(Number(item.total), Number(summary)) - 100 <=
            25
          )
            rowBg = "rgb(249, 185, 185)";
          else rowBg = "rgb(245, 147, 147)";
        } else {
          if (
            100 - countTotalPercentage(Number(item.total), Number(summary)) <=
            5
          )
            rowBg = "rgb(249, 224, 175)";
          else if (
            100 - countTotalPercentage(Number(item.total), Number(summary)) <=
            15
          )
            rowBg = "rgb(207, 229, 214)";
          else if (
            100 - countTotalPercentage(Number(item.total), Number(summary)) <=
            25
          )
            rowBg = "rgb(163, 216, 178)";
          else rowBg = "rgb(148, 204, 147)";
        }
      }
    }
  })();

  if (item.hasOwnProperty("promotion")) {
    if (item.promotion === "Champions League") {
      standingBg = "#85e399";
    } else if (item.promotion === "Relegation") {
      standingBg = "#f59393";
    } else if (item.promotion === "UEFA Conference League Qualification") {
      standingBg = "#f9e0af";
    } else if (item.promotion === "UEFA Europa League") {
      standingBg = "#bfdac6";
    }
  }

  if (item.home === teamName) {
    bgForTotal1 = newBg1;
  } else {
    bgForTotal2 = newBg1;
  }

  if (teamName === "both") {
    if (item.t1 > item.t2) {
      bgForTotal1WhenBoth = "rgba(151, 206, 167, 0.40)";
      bgForTotal2WhenBoth = "rgba(255, 95, 95, 0.40)";
    } else if (item.t1 < item.t2) {
      bgForTotal1WhenBoth = "rgba(255, 95, 95, 0.40)";
      bgForTotal2WhenBoth = "rgba(151, 206, 167, 0.40)";
    } else {
      bgForTotal1WhenBoth = "rgba(255, 192, 71, 0.40)";
      bgForTotal2WhenBoth = "rgba(255, 192, 71, 0.40)";
    }
  }

  function checkCoaches(teamId, item, nextItem) {
    if (item !== undefined && nextItem !== undefined) {
      let teamStats =
        item?.away_stats?.away_id === teamId
          ? item?.away_stats
          : item?.home_stats;
      let nextTeamStats =
        nextItem?.away_stats?.away_id === teamId
          ? nextItem?.away_stats
          : nextItem?.home_stats;

      if (teamStats?.coach?.id !== nextTeamStats?.coach?.id) {
        return {
          team: item.away_stats.away_id === teamId ? item.away : item.home,
          oldCoach: teamStats.coach.name,
          newCoach: nextTeamStats.coach.name,
        };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  return (
    <>
      <div
        className="table-row"
        style={{
          borderRadius: summaried && 0,
          gridTemplateColumns: colsWidth,
          background: rowBg,
        }}
      >
        {withCheckbox && (
          <p className={`table-row`} style={{ borderRadius: summaried && 0 }}>
            <input
              id={id}
              type="checkbox"
              defaultChecked
              checked={isChecked}
              onChange={handleClick}
            />
          </p>
        )}

        {Object.entries(item).map(([key, value], index) => {
          if (
            key != "home_stats" &&
            key != "away_stats" &&
            key !== "promotion"
          ) {
            if (key === "refereeObj") {
              return (
                <span className={`table-row__${key}`}>
                  <Link to={`/referee/${value?.id}`}>
                    {value?.name}
                  </Link>
                </span>
              );
            }
            if (key === "teamObj") {
              return (
                <span className={`table-row__${key}`}>
                  <Link to={`/team/${value?.id}`}>
                    {value?.name}
                  </Link>
                </span>
              );
            }
            if (typeof value !== "object" || key === "ref") {
              let greenBg = "none";
              let bg = "";
              if (key !== "ref") {
                if (showGreen) {
                  if (+value.split("/")[0] / +value.split("/")[1] >= 0.9) {
                    greenBg = "#97CEA7";
                  } else if (
                    +value.split("/")[0] / +value.split("/")[1] >=
                    0.8
                  ) {
                    greenBg = "rgba(151, 206, 167, 0.40)";
                  } else if (
                    +value.split("/")[0] / +value.split("/")[1] >=
                    0.7
                  ) {
                    greenBg = "rgba(151, 206, 167, 0.20)";
                  }
                }
                if (teamName === "both") {
                  if (key === "home") {
                    bg = newBg1;
                  } else if (key === "away") {
                    bg = newBg2;
                  } else if (key === "t1") {
                    bg = bgForTotal1WhenBoth;
                  } else if (key === "t2") {
                    bg = bgForTotal2WhenBoth;
                  }
                } else {
                  bg = value === teamName ? newBg1 : greenBg;
                }

                if (key === "id" && standingBg !== "") {
                  bg = standingBg;
                }

                if (key === "t1" && bgForTotal1 !== "" && teamName !== "both") {
                  bg = bgForTotal1;
                }

                if (key === "t2" && bgForTotal2 !== "" && teamName !== "both") {
                  bg = bgForTotal2;
                }
              }
              return (
                <span
                  className={`table-row__${key}`}
                  key={index}
                  style={{
                    borderRadius: summaried && 0,
                    background: bg,
                  }}
                >
                  <div>
                    {withCheckbox &&
                      key === "ref" &&
                      typeof item?.ref === "object" && (
                        <Tooltip data={item?.ref} />
                      )}
                    {key !== "ref" && value}
                  </div>
                  <div style={{ display: "flex", gap: 2, marginLeft: 2 }}>
                    {index === 2 &&
                      redCards[id]?.home?.map((time, ind) => (
                        <div
                          key={ind}
                          style={{
                            paddingTop: 3,
                            width: 19,
                            height: 19,
                            borderRadius: 2,
                            background: "#FF5F5F",
                            color: "white",
                            fontSize: 9,
                            fontWeight: 600,
                          }}
                        >
                          {time}
                        </div>
                      ))}
                    {index === 5 &&
                      redCards[id]?.away?.map((time, ind) => (
                        <div
                          key={ind}
                          style={{
                            paddingTop: 3,
                            width: 19,
                            height: 19,
                            borderRadius: 2,
                            background: "#FF5F5F",
                            color: "white",
                            fontSize: 9,
                            fontWeight: 600,
                          }}
                        >
                          {time}
                        </div>
                      ))}
                  </div>
                </span>
              );
            }
            return (
              <div key={index} className="table-row__lastmatches">
                {key !== "ref" &&
                  key !== "refereeObj" &&
                  value?.map((res, index) => {
                    switch (res) {
                      case "win":
                        return (
                          <div
                            key={index}
                            className="table-row__lastmatches-item"
                            style={{ background: "#CFFF47" }}
                          ></div>
                        );
                      case "lose":
                        return (
                          <div
                            key={index}
                            className="table-row__lastmatches-item"
                            style={{ background: "#FF5F5F" }}
                          ></div>
                        );
                      default:
                        return (
                          <div
                            key={index}
                            className="table-row__lastmatches-item"
                            style={{ background: "#FFC047" }}
                          ></div>
                        );
                    }
                  })}
              </div>
            );
          }
        })}
      </div>
      {checkCoaches(teamId, item, nextItem) !== null && (
        <div className="table-row table-coach">
          <p>
            {checkCoaches(teamId, item, nextItem).team}: новый тренер -{" "}
            {checkCoaches(teamId, item, nextItem).newCoach} (старый -{" "}
            {checkCoaches(teamId, item, nextItem).oldCoach})
          </p>
        </div>
      )}
    </>
  );
};
