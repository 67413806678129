import { Layout } from "../../layouts/Layout";
import { LineFilter } from "../../components/LineFilter/LineFilter";
import { LeagueBar } from "./components/LeagueBar/LeagueBar";
import { useStats } from "./hooks/useStats";
import "../../assets/styles/league.scss";
import { useDispatch, useSelector } from "react-redux";
import { paginatorSelector, setPage } from "../../store/paginatorSlice";
import { NumberFilter } from "../../components/NumberFilter/NumberFilter";
import { MatchesCard } from "../../components/MatchesCard/MatchesCard";
import { useLocation } from "react-router-dom";
import { Table } from "../../components/Table/Table";
import { Paginator } from "../../components/Paginator/Paginator";
import { StatsCards } from "../../components/StatsCards/StatsCards";
import { StatsPercentage } from "../../components/StatsPercentage/StatsPercentage";
import { ChartCard } from "../../components/ChartCard/ChartCard";
import { AverageStatsCard } from "../../components/AverageStatsCard/AverageStatsCard";
import { useEffect } from "react";
import { RefereesCards } from "./components/RefereesCards/RefereesCards";
import { TimeFilter } from "../../components/TimeFilter/TimeFIlter";
import { useWindowResize } from "../../utils/useWindowResize";
import { LeaguesAndCupsCard } from "./components/LeaguesAndCupsCard/LeaguesAndCupsCard";
import { StandingsCards } from "../../components/StandingsCards/StandingsCards";

export const League = () => {
  const id =
    +useLocation().pathname.split("/")[
      useLocation().pathname.split("/").length - 1
    ];
  const {
    header,
    teams,
    tableTeams,
    tableStats,
    tableReferees,
    tableStandings,
    matches,
    descriptiveStats,
    statsBySeason,
    filters,
    timerange,
    hasTimerange,
    leaguesAndCups,
    loadingTeams,
    loadingReferees,
    loadingSeason,
    tableWithColors,
    leagueStatus,
    statsStatus,
    refereeStatus,
    setSeasonStatType,
    setSeasonTime,
    setStatType,
    setCount,
    setSeasons,
    setTime,
    setPlace,
    onTeamsApplyClick,
    onStatsApplyClick,
    onLoadMoreClick,
    onRefereeApplyClick,
    onLoadMoreClickOther,
    changeTableColors,
  } = useStats(id);
  const dispatch = useDispatch();
  const page = useSelector(paginatorSelector);

  const curPage = useLocation().search;

  useEffect(() => {
    if (curPage) dispatch(setPage(curPage.split("=")[1]));
    else dispatch(setPage("Teams"));
  }, []);

  console.log(tableTeams)

  return (
    <Layout>
      <div className="container">
        <LeagueBar
          league={header?.league.name}
          country={header?.country.name}
          general_name={header?.country.general_name}
          image_path={header?.country.image_path}
          stats={header?.league.stats}
        />
        {header?.league.name && (
          <h1 className="title league-page__title">
            {header?.league.name} Statistics
          </h1>
        )}
        <Paginator pages={["Teams", "Referees", "Standings"]} />
        {page === "Teams" && (
          <div className="league-container">
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <LineFilter
                styles={{
                  borderRadius: "100px 0 0 100px",
                }}
                filters={teams?.stats?.stats?.stats?.main || []}
                setFilter={setStatType}
                activeFilter={filters?.stat_type || teams?.stat_type}
                others={teams?.stats?.stats?.stats?.other || []}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                gap: "20px",
              }}
            >
              <NumberFilter
                activeFilter={filters?.count || teams?.count}
                filters={["5", "10", "15", "20", "30", "40", "50", "100"]}
                setFilter={setCount}
              />
              <LineFilter
                styles={{
                  borderRadius: "100px",
                }}
                filters={filters?.seasons || {}}
                setFilter={setSeasons}
                activeFilter={filters?.seasons || teams?.seasons}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: 432,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "20px",
                }}
              >
                <TimeFilter
                  activeFilter={filters?.time || teams?.time}
                  filters={filters?.time || {}}
                  setFilter={setTime}
                  timerangeValues={timerange}
                  withRange={
                    teams?.stats?.stats_minute?.stats?.main?.includes(
                      filters?.stat_type
                    ) ||
                    teams?.stats?.stats_minute?.stats?.other.includes(
                      filters?.stat_type
                    )
                  }
                />
                <LineFilter
                  activeFilter={filters?.place || teams?.params?.team?.place}
                  filters={["Home", "Away", "All"]}
                  setFilter={setPlace}
                />
              </div>
              <button className="stats__button" onClick={onTeamsApplyClick}>
                Apply
              </button>
            </div>
            {!tableTeams?.table.length && !loadingTeams ? (
              <div className="no-data">
                <div className="no-data__block">
                  <h4>OOPS!</h4>
                  <p>
                    Unfortunately, don't have data on these statistical metrics.
                    Perhaps we'll include them in the future!
                  </p>
                </div>
              </div>
            ) : leagueStatus === 422 ? (
              <div className="no-data">
                <div className="no-data__block">
                  <h4>OOPS!</h4>
                  <p>
                    The filters you've applied don't yield any results. Try a
                    different combination to find the information you're looking
                    for.
                  </p>
                </div>
              </div>
            ) : (
              <Table
                headerItems={[
                  { text: "№" },
                  { text: "Team" },
                  { text: "MP" },
                  { text: "W" },
                  { text: "D" },
                  { text: "L" },
                  { text: "Goals" },
                  { text: "S/C" },
                  { text: "PTS" },
                  { text: "Last 5" },
                  { text: "P/M" },
                  { text: "AT" },
                  { text: "AIT" },
                  { text: "AITO" },
                  { text: "BTTS" },
                  { text: "CSW" },
                  { text: "Over 1.5" },
                  { text: "Over 2.5" },
                  { text: "Over 3.5" },
                ]}
                colsWidth="28px 200px 50px 50px 50px 50px 60px 60px 60px 80px 60px 60px 60px 70px 70px 70px 70px 70px 70px"
                data={tableTeams}
                summaried
                loading={loadingTeams}
              />
            )}

            <h1 className="title league-page__title">{header?.league.name}</h1>
            <div className="la-league__stats">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 60,
                  maxWidth: 850,
                  width: "100%",
                }}
              >
                <StatsCards descriptiveStats={descriptiveStats} />
                <StatsPercentage descriptiveStats={descriptiveStats} />
                <ChartCard descriptiveStats={descriptiveStats} />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <AverageStatsCard
                    descriptiveStats={descriptiveStats}
                    side="left"
                    first_categ="fouls"
                    second_categ="shots"
                    third_categ="offsides"
                    fourth_categ="throw-ins"
                  />
                  <AverageStatsCard
                    descriptiveStats={descriptiveStats}
                    side="right"
                    first_categ="ball possessions"
                    second_categ="goal kicks"
                    third_categ="corners"
                    fourth_categ="yellow cards"
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 30,
                }}
              >
                <MatchesCard
                  title="Fixtures"
                  type="next"
                  matches={matches?.next}
                  onLoadMoreClick={onLoadMoreClick}
                />
                <LeaguesAndCupsCard
                  title="Leagues and Cups"
                  data={leaguesAndCups}
                  onLoadMoreClick={onLoadMoreClickOther}
                />
                <MatchesCard
                  title="Last matches"
                  type="previous"
                  matches={matches?.previous}
                  onLoadMoreClick={onLoadMoreClick}
                />
              </div>
            </div>
            <h1 className="title league-page__title">Stats by season</h1>
            <div
              style={{
                marginBottom: "20px",
                gap: "20px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 20,
                }}
              >
                <LineFilter
                  styles={{
                    borderRadius: "100px 0 0 100px",
                  }}
                  filters={teams?.stats?.stats?.stats?.main || []}
                  setFilter={setSeasonStatType}
                  activeFilter={statsBySeason?.stat_type || teams?.stat_type}
                  others={teams?.stats?.stats?.stats?.other || []}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: 632,
                  }}
                >
                  <TimeFilter
                    activeFilter={statsBySeason?.time || teams?.time}
                    filters={statsBySeason?.time || {}}
                    setFilter={setSeasonTime}
                    timerangeValues={timerange}
                    withRange={teams?.stats?.stats_minute?.stats?.main?.includes(
                      statsBySeason?.stat_type
                    )}
                  />
                  <button className="stats__button" onClick={onStatsApplyClick}>
                    Apply
                  </button>
                </div>
              </div>
            </div>
            {!tableStats?.table.length && !loadingSeason ? (
              <div className="no-data">
                <div className="no-data__block">
                  <h4>OOPS!</h4>
                  <p>
                    Unfortunately, don't have data on these statistical metrics.
                    Perhaps we'll include them in the future!
                  </p>
                </div>
              </div>
            ) : statsStatus === 422 ? (
              <div className="no-data">
                <div className="no-data__block">
                  <h4>OOPS!</h4>
                  <p>
                    The filters you've applied don't yield any results. Try a
                    different combination to find the information you're looking
                    for.
                  </p>
                </div>
              </div>
            ) : (
              <Table
                headerItems={[
                  { text: "Season" },
                  { text: "Matches" },
                  { text: "Avg. total" },
                  { text: "Avg. total (Home)" },
                  { text: "Avg. total (Away)" },
                  { text: "Win (Home)" },
                  { text: "Draw" },
                  { text: "Win (Away)" },
                ]}
                data={tableStats}
                colsWidth="160px 140px 120px 190px 190px 120px 120px 250px"
                summaried
                loading={loadingSeason}
              />
            )}
          </div>
        )}
        {page === "Referees" && (
          <div className="league-container">
            <div
              style={{
                width: "max-content",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <LineFilter
                styles={{
                  borderRadius: "100px 0 0 100px",
                }}
                filters={teams?.stats?.stats?.stats?.main || []}
                setFilter={setStatType}
                activeFilter={filters?.stat_type || teams?.stat_type}
                others={teams?.stats?.stats?.stats?.other || []}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexWrap: "wrap",
                gap: "20px",
              }}
            >
              <NumberFilter
                activeFilter={filters?.count || teams?.count}
                filters={["5", "10", "15", "20", "30", "40", "50", "100"]}
                setFilter={setCount}
              />
              <LineFilter
                styles={{
                  borderRadius: "100px",
                }}
                filters={filters?.seasons || {}}
                setFilter={setSeasons}
                activeFilter={filters?.seasons || teams?.seasons}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                gap: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "20px",
                }}
              >
                <TimeFilter
                  activeFilter={filters?.time || teams?.time}
                  filters={filters?.time || {}}
                  setFilter={setTime}
                  timerangeValues={timerange}
                  withRange={
                    teams?.stats?.stats_minute?.stats?.main?.includes(
                      filters?.stat_type
                    ) ||
                    teams?.stats?.stats_minute?.stats?.other?.includes(
                      filters?.stat_type
                    )
                  }
                />
              </div>
              <div>
                <button className="stats__button" onClick={changeTableColors}>
                  Display Color Distribution
                </button>
              </div>
              <button className="stats__button" onClick={onRefereeApplyClick}>
                Apply
              </button>
            </div>
            {!tableReferees?.table.length && !loadingReferees  ? (
              <div className="no-data">
                <div className="no-data__block">
                  <h4>OOPS!</h4>
                  <p>
                    Unfortunately, don't have data on these statistical metrics.
                    Perhaps we'll include them in the future!
                  </p>
                </div>
              </div>
            ) : refereeStatus === 422 ? (
              <div className="no-data">
                <div className="no-data__block">
                  <h4>OOPS!</h4>
                  <p>
                    The filters you've applied don't yield any results. Try a
                    different combination to find the information you're looking
                    for.
                  </p>
                </div>
              </div>
            ) : (
              <Table
                headerItems={[
                  { text: "№" },
                  { text: "Referee" },
                  { text: "Matches" },
                  { text: "Win (Home)" },
                  { text: "Draw" },
                  { text: "Win (Away)" },
                  { text: "Avg. total (Home)" },
                  { text: "Avg. total (Away)" },
                  { text: "Avg. total / match" },
                  { text: "Total" },
                ]}
                colsWidth="57px 309px 100px 100px 100px 100px 110px 110px 164px 120px"
                data={tableReferees}
                isReferee={tableWithColors}
                summaried
                loading={loadingReferees}
              />
            )}
            <RefereesCards />
          </div>
        )}
        {page === "Standings" && (
          <>
            <Table
              headerItems={[
                { text: "№" },
                { text: "Team" },
                { text: "Matches" },
                { text: "W" },
                { text: "D" },
                { text: "L" },
                { text: "Goals" },
                { text: "Points" },
              ]}
              colsWidth="50px 350px 148px 148px 148px 148px 148px 148px"
              data={tableStandings}
            />
            <StandingsCards />
          </>
        )}
      </div>
    </Layout>
  );
};
