import { numToPerc } from "../../../utils/numToPerc"

export const configureStats = (response) => {
  const sumMatches =
    response.venue.home_wins + response.venue.away_wins + response.venue.draws
  const sumGoals = Object.values(response.goals.count_by_fixture).reduce(
    (acc, value) => acc + value,
    0
  )
  const sumMinutes = Object.values(response.goals.count_by_minutes).reduce(
    (acc, value) => acc + value,
    0
  )

  const sumMinutesArr = Object.values(response.goals.count_by_minutes).reduce(
    (acc, value) => [...acc, value],
    []
  )

  const sumBallPos = Object.values(response.stats["Ball Possession %"]).reduce(
    (acc, value) => acc + value,
    0
  )
  const sumShots = Object.values(response.stats["Shots Total"]).reduce(
    (acc, value) => acc + value,
    0
  )
  const sumYellowCards = Object.values(response.stats["Yellow cards"]).reduce(
    (acc, value) => acc + value,
    0
  )
  const sumCorners = Object.values(response.stats["Corners"]).reduce(
    (acc, value) => acc + value,
    0
  )
  const sumThrowins = Object.values(response.stats["Throw-ins"]).reduce(
    (acc, value) => acc + value,
    0
  )
  const sumFouls = Object.values(response.stats["Fouls"]).reduce(
    (acc, value) => acc + value,
    0
  )
  const sumGoalKicks = Object.values(response.stats["Goal kicks"]).reduce(
    (acc, value) => acc + value,
    0
  )
  const sumOffsides = Object.values(response.stats["Offsides"]).reduce(
    (acc, value) => acc + value,
    0
  )

  const needableTimeLine = sumMinutesArr.slice(0, 90)

  const resultTen = []

  for (let i = 0; i < needableTimeLine.length; i += 10) {
    let sum = 0;
    for (let j = i; j < i + 10 && j < needableTimeLine.length; j++) {
      sum += needableTimeLine[j];
    }
    resultTen.push(String(Math.floor((sum / response.goals.all) * 100)));
  }

  const resultFifteen = []

  for (let i = 0; i < needableTimeLine.length; i += 15) {
    let sum = 0;
    for (let j = i; j < i + 15 && j < needableTimeLine.length; j++) {
      sum += needableTimeLine[j];
    }
    resultFifteen.push(String(Math.floor((sum / response.goals.all) * 100)));
  }

  return {
    goalsPerMatch: (response.goals.all / response.fixtures_count).toFixed(2),
    goalsPerMatchHome: (response.goals.home / response.fixtures_count).toFixed(
      2
    ),
    goalsPerMatchAway: (response.goals.away / response.fixtures_count).toFixed(
      2
    ),
    goalsPerFirst: (
      response.goals.first_half / response.fixtures_count
    ).toFixed(2),
    goalsPerSecond: (
      response.goals.second_half / response.fixtures_count
    ).toFixed(2),
    avgPerMinute: (response.goals.all / response.goals.minutes).toFixed(2),
    winHome: numToPerc(response.venue.home_wins, sumMatches).toFixed(),
    draws: numToPerc(response.venue.draws, sumMatches).toFixed(),
    winAway: numToPerc(response.venue.away_wins, sumMatches).toFixed(),
    zeroToOne: numToPerc(
      Object.entries(response.goals.count_by_fixture).reduce(
        (acc, [key, value]) => {
          if (key <= 1) return acc + value
          return acc
        },
        0
      ),
      sumGoals
    ).toFixed(),
    twoToThree: numToPerc(
      Object.entries(response.goals.count_by_fixture).reduce(
        (acc, [key, value]) => {
          if (key > 1 && key <= 3) return acc + value
          return acc
        },
        0
      ),
      sumGoals
    ).toFixed(),
    fourPlus: numToPerc(
      Object.entries(response.goals.count_by_fixture).reduce(
        (acc, [key, value]) => {
          if (key > 3) return acc + value
          return acc
        },
        0
      ),
      sumGoals
    ).toFixed(),
    tenMinsPerc: resultTen,
    fifteenMinsPerc: resultFifteen,
    ballPos: numToPerc(
      response.stats["Ball Possession %"].count,
      sumBallPos
    ).toFixed(2),
    ballPosHome: numToPerc(
      response.stats["Ball Possession %"].sm_home,
      sumBallPos
    ).toFixed(2),
    ballPosAway: numToPerc(
      response.stats["Ball Possession %"].sm_away,
      sumBallPos
    ).toFixed(2),
    shotsTotal: numToPerc(
      response.stats["Shots Total"].count,
      sumShots
    ).toFixed(2),
    shotsTotalHome: numToPerc(
      response.stats["Shots Total"].sm_home,
      sumShots
    ).toFixed(2),
    shotsTotalAway: numToPerc(
      response.stats["Shots Total"].sm_away,
      sumShots
    ).toFixed(2),
    corners: numToPerc(response.stats["Corners"].count, sumCorners).toFixed(2),
    cornersHome: numToPerc(
      response.stats["Corners"].sm_home,
      sumCorners
    ).toFixed(2),
    cornersAway: numToPerc(
      response.stats["Corners"].sm_away,
      sumCorners
    ).toFixed(2),
    yellowCards: numToPerc(
      response.stats["Yellow cards"].count,
      sumYellowCards
    ).toFixed(2),
    yellowCardsHome: numToPerc(
      response.stats["Yellow cards"].sm_home,
      sumYellowCards
    ).toFixed(2),
    yellowCardsAway: numToPerc(
      response.stats["Yellow cards"].sm_away,
      sumYellowCards
    ).toFixed(2),
    throwins: numToPerc(response.stats["Throw-ins"].count, sumThrowins).toFixed(
      2
    ),
    throwinsHome: numToPerc(
      response.stats["Throw-ins"].sm_home,
      sumThrowins
    ).toFixed(2),
    throwinsAway: numToPerc(
      response.stats["Throw-ins"].sm_away,
      sumThrowins
    ).toFixed(2),
    fouls: numToPerc(response.stats["Throw-ins"].count, sumFouls).toFixed(2),
    foulsHome: numToPerc(response.stats["Throw-ins"].sm_home, sumFouls).toFixed(
      2
    ),
    foulsAway: numToPerc(response.stats["Throw-ins"].sm_away, sumFouls).toFixed(
      2
    ),
    goalKicks: numToPerc(
      response.stats["Throw-ins"].count,
      sumGoalKicks
    ).toFixed(2),
    goalKicksHome: numToPerc(
      response.stats["Throw-ins"].sm_home,
      sumGoalKicks
    ).toFixed(2),
    goalKicksAway: numToPerc(
      response.stats["Throw-ins"].sm_away,
      sumGoalKicks
    ).toFixed(2),
    offsides: numToPerc(response.stats["Offsides"].count, sumOffsides).toFixed(
      2
    ),
    offsidesHome: numToPerc(
      response.stats["Offsides"].sm_home,
      sumOffsides
    ).toFixed(2),
    offsidesAway: numToPerc(
      response.stats["Offsides"].sm_away,
      sumOffsides
    ).toFixed(2),
  }
}
