import "./Tooltip.scss"
import refereeIcon from "../../assets/svg/referee.svg"
import noRefereeIcon from "../../assets/svg/noReferee.svg"
import { useState } from "react"
import { Link } from "react-router-dom"

export const Tooltip = (data) => {
  const keys = ["Referee", "fouls", "red_cards", "yellow_cards"]

  const [show, setShow] = useState(false)
  return (
    (data?.data?.id !== undefined ? <Link to={`/referee/${data.data.id}`}>
      <div style={{ position: "relative" }}>
        <img
          style={{ cursor: "pointer" }}
          src={refereeIcon}
          alt="referee"
          onMouseEnter={() => {
            setShow((prev) => !prev)
          }}
          onMouseLeave={() => {
            setShow((prev) => !prev)
          }}
        />
        {show && (
          <div className="tooltip">
            <div className="tooltip_item">
              <span className="tooltip_item__key">{keys[0]}:</span>{" "}
              {data?.data?.name}
            </div>
            {Object.entries(data?.data?.stats).map(([_, value], index) => (
              <div className="tooltip_item">
                <span className="tooltip_item__key">{keys[index + 1]}</span>:{" "}
                {value > 0 ? value.toFixed(2) : 0}
              </div>
            ))}
          </div>
        )}
      </div>
    </Link> : <img src={noRefereeIcon} alt="referee"/>)
  )
}
 