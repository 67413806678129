import { useEffect, useState } from "react";
import {
  getReferee,
  getRefereeLeagueStats,
  getRefereeSeasonStats,
  getRefereesList,
  getOtherReferees,
} from "../http";
import { prepareRefSeasonTable } from "../utils/prepareRefSeasonTable";
import { prepareTourSeasonTable } from "../utils/prepareTourSeasonTable";
import { prepareRefereeHeader } from "../utils/prepareRefereeHeader";
import { getRefereeFixtures } from "../../PreMatch/http";
import { prepareFilters } from "../../PreMatch/utils/prepareFilters";
import { prepareFixturesTable } from "../../../utils/prepareFixturesTable";
import { refereeResFotTables } from "../../../utils/refereeResForTables";
import { sleep } from "../../../utils/sleep";

export const useRefereeStats = (id) => {
  const [refereeData, setRefereeData] = useState({});
  const [tableSeason, setTableSeason] = useState();
  const [tableTournaments, setTableTournaments] = useState();
  const [seasonFilters, setSeasonFilters] = useState();
  const [filters, setFilters] = useState();
  const [tournamentsFilters, setTournamentsFilters] = useState();
  const [timerange, setTimerange] = useState({});
  const [tableRefereeFixtures, setTableRefereeFixtures] = useState();
  const [tableRefereeOdds, setTableRefereeOdds] = useState();
  const [refsList, setRefsList] = useState([]);
  const [seasonsType, setSeasonsType] = useState("sum");
  const [tournamentsType, setTournamentsType] = useState("sum");

  const [tableRefereeManual, setTableRefereeManual] = useState({
    table: [],
    sums: {},
  });

  const [refereeFixturesLeagues, setRefereeFixturesLeagues] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loadingSeason, setLoadingSeason] = useState(false);
  const [loadingTournament, setLoadingTournament] = useState(false);
  const [refereeStatus, setRefereeStatus] = useState(null);
  const [seasonStatus, setSeasonStatus] = useState(null);
  const [tournamentStatus, setTournamentStatus] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const refs = await getOtherReferees(1, id);
      setRefsList(refs);
      const headerResponse = await getReferee(id);
      setRefereeData(headerResponse);

      setRefereeFixturesLeagues(headerResponse.params.leagues);

      const seasons = {};
      for (const key of headerResponse.params.seasons) {
        if (headerResponse.params.default.seasons.includes(key))
          seasons[key] = true;
        else seasons[key] = false;
      }

      const times = {};
      for (const key of ["All match", "1st half", "2nd half"]) {
        if (typeof key === "string") {
          if (headerResponse.params.default.time === key) times[key] = true;
          else times[key] = false;
        }
      }

      const newLeagues = prepareRefereeHeader(headerResponse).leagues;

      for (const league of headerResponse.params.leagues) {
        for (const leag of headerResponse.params.default.leagues) {
          if (leag === league.id) newLeagues[league.name] = true;
        }
      }

      setFilters({
        count: headerResponse.params.default.count,
        time: times,
        stat_type: headerResponse.params.default.stat_type,
        seasons: seasons,
        leagues: newLeagues,
        only_with: headerResponse.params.default.only_with,
        against_teams: headerResponse.params.default.against_teams,
      });

      try {
        setLoadingSeason(true);
        await sleep(500);
        const seasonResponse = await getRefereeSeasonStats(
          id,
          headerResponse.params.default.seasons[0]
        );

        setTableSeason(prepareRefSeasonTable(seasonResponse.data, seasonsType));
        setSeasonStatus(seasonResponse.status)
      } catch (error) {
        if (error.response) {
          console.error("Status", error.response.status);
        } else if (error.request) {
          console.error("Error of request:", error.request);
        } else {
          console.error("Simle error is:", error);
        }
      } finally {
        setLoadingSeason(false);
      }

      setSeasonFilters({
        id: id,
        season_name: headerResponse.params.default.seasons[0],
      });

      try {
        setLoadingTournament(true);
        await sleep(500);
        const tournamentsResponse = await getRefereeLeagueStats(
          id,
          headerResponse.params.leagues[0].id
        );
        setTableTournaments(
          prepareTourSeasonTable(tournamentsResponse.data, tournamentsType)
        );
        setTournamentStatus(tournamentsResponse.status)
      } catch (error) {
        if (error.response) {
          console.error("Status", error.response.status);
        } else if (error.request) {
          console.error("Error of request:", error.request);
        } else {
          console.error("Simle error is:", error);
        }
      } finally {
        setLoadingTournament(false);
      }

      setTournamentsFilters({
        id: id,
        league_id: headerResponse.params.leagues[0].id,
      });

      const refereeTimes = {};
      for (const key of ["All match", "1st half", "2nd half"]) {
        if (typeof key === "string") {
          if (headerResponse.params.default.time === key)
            refereeTimes[key] = true;
          else refereeTimes[key] = false;
        }
      }

      const refereeSeasons = {};
      for (const key of headerResponse.params.seasons) {
        if (headerResponse.params.default.seasons.includes(key))
          refereeSeasons[key] = true;
        else refereeSeasons[key] = false;
      }

      const refereeLeagues = {};

      for (const league of headerResponse.params.leagues) {
        refereeLeagues[league?.name] = false;
        for (const leag of headerResponse.params.default.leagues) {
          if (leag === league?.id) refereeLeagues[league?.name] = true;
          else refereeLeagues[league?.name] = false;
        }
      }

      try {
        setLoading(true);
        await sleep(500);
        const refereeFixturesResponse = await getRefereeFixtures(
          headerResponse.params.default.stat_type,
          headerResponse.params.default.count,
          headerResponse.referee.id,
          headerResponse.params.default.only_with,
          prepareFilters(
            {
              time: refereeTimes,
              leagues: refereeLeagues,
              seasons: refereeSeasons,
              against_teams: headerResponse.params.default.against_teams,
            },
            headerResponse.params.leagues
          )
        );
        setTableRefereeFixtures(
          prepareFixturesTable(refereeFixturesResponse.data)
        );
        setRefereeStatus(refereeFixturesResponse.status)
      } catch (error) {
        if (error.response) {
          console.error("Status", error.response.status);
        } else if (error.request) {
          console.error("Error of request:", error.request);
        } else {
          console.error("Simle error is:", error);
        }
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const setSeasonsSums = (activeKey) => {
    setSeasonsType(activeKey === "Average" ? "avg" : "sum");
  };

  const setTournamentsSums = (activeKey) => {
    setTournamentsType(activeKey === "Average" ? "avg" : "sum");
  };

  const setSeasonSeasons = (activeSeason) => {
    setSeasonFilters((prev) => ({ ...prev, season_name: activeSeason }));
  };

  const setTournamentsLeagues = (activeLeague) => {
    const leagues = refereeData?.params?.leagues;
    for (const league of leagues) {
      if (league.name === activeLeague)
        setTournamentsFilters((prev) => ({ ...prev, league_id: league.id }));
    }
    setTournamentsFilters((prev) => ({ ...prev, league_name: activeLeague }));
  };

  const setStatType = (activeKey) => {
    setFilters((prev) => ({ ...prev, stat_type: activeKey }));
  };

  const setLeagues = (activeLeague) => {
    const league = filters?.leagues;

    var newLeague = {};
    let count = 1;
    for (const [key] of Object.entries(league)) {
      newLeague[key] =
        (key === activeLeague && !league[key]) ||
        (league[key] && activeLeague !== key);
      if (newLeague[key]) count++;
    }
    if (count > 1) setFilters((prev) => ({ ...prev, leagues: newLeague }));
  };

  const setAllLeagues = (selectAll, val) => {
    const league = filters?.leagues;

    var newLeague = {};
    for (const [key] of Object.entries(league)) {
      newLeague[key] = selectAll;
    }
    if (val) newLeague[val] = true;
    setFilters((prev) => ({ ...prev, leagues: newLeague }));
  };

  const setCount = (activeKey) => {
    setFilters((prev) => ({
      ...prev,
      count: activeKey,
    }));
  };

  const setSeasons = (activeSeason) => {
    const keyArray = activeSeason.split("/");
    var newKey =
      keyArray.length < 2 ? activeSeason : `${keyArray[0]}/${keyArray[1]}`;
    const season = filters?.seasons;

    var newSeason = {};
    let count = 1;
    for (const [key] of Object.entries(season)) {
      newSeason[key] =
        (key === newKey && !season[key]) || (season[key] && newKey !== key);
      if (newSeason[key]) count++;
    }
    if (count > 1) setFilters((prev) => ({ ...prev, seasons: newSeason }));
  };

  const setAllSeasons = (selectAll, val) => {
    const season = filters?.seasons;

    var newSeason = {};
    for (const [key] of Object.entries(season)) {
      newSeason[key] = selectAll;
    }
    if (val) newSeason[val] = true;
    setFilters((prev) => ({ ...prev, seasons: newSeason }));
  };

  const setTime = (activeKey) => {
    const time = filters.time;
    if (typeof activeKey === "object") setTimerange(activeKey);
    else setTimerange({});
    var newTime = {};
    for (const [key] of Object.entries(time)) {
      newTime[key] = activeKey === key ? true : false;
    }
    setFilters((prev) => ({ ...prev, time: newTime }));
  };

  const setOnlyWith = (activeKey) => {
    setFilters((prev) => ({
      ...prev,
      only_with: activeKey.charAt(0).toUpperCase() + activeKey.slice(1),
    }));
  };

  const setRanked = (activeKey) => {
    setFilters((prev) => ({
      ...prev,
      against_teams: activeKey,
    }));
  };

  // функция для расчета over/under таблицы снизу
  const overUnderTable = (arr) => {
    const amountRows = Math.max.apply(
      Math,
      arr.map((i) => i.t)
    );
    return Array.from({ length: amountRows + 1 }, (_, i) => {
      const overValue = arr.filter((el) => el.t >= i + 1).length;
      return {
        total: `${i + 0.5}`,
        over: `${overValue}/${arr.length}`,
        under: `${arr.length - overValue}/${arr.length}`,
      };
    });
  };

  // функция для расчета T10/T1U и T2O/T2U таблиц снизу
  const secondAndThirdOverUnderTable = (arr) => {
    const amountRows = Math.max.apply(Math, arr);
    return Array.from({ length: amountRows + 1 }, (_, i) => {
      const overValue = arr.filter((el) => el >= i + 1).length;
      return {
        total: `${i + 0.5}`,
        over: `${overValue}/${arr.length}`,
        under: `${arr.length - overValue}/${arr.length}`,
      };
    });
  };

  // функция для расчета H1 и H2 таблиц снизу
  const fourthAndFifthTable = (arr) => {
    let negativeMax = -1000;
    let positiveMax = -1000;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] > arr[i][1]) {
        if (arr[i][0] - arr[i][1] > negativeMax) {
          negativeMax = arr[i][0] - arr[i][1];
        }
      } else {
        if (arr[i][1] - arr[i][0] > positiveMax) {
          positiveMax = arr[i][1] - arr[i][0];
        }
      }
    }
    let res1 = Array.from({ length: negativeMax + 1 }, (_, i) => {
      const overValue = arr.filter((el) => el[0] - el[1] > i).length;
      return { total: `${-0.5 - i}`, handicap: `${overValue}/${arr.length}` };
    });
    let res2 = Array.from({ length: positiveMax + 1 }, (_, i) => {
      const overValue = arr.filter((el) => el[0] - el[1] >= -i).length;
      return { total: `${i + 0.5}`, handicap: `${overValue}/${arr.length}` };
    });
    let res = res1.concat(res2);

    return res;
  };

  const setRefereeTableTotalsManual = (activeArr) => {
    if (activeArr?.length > 0) {
      setTableRefereeOdds({
        table: [
          { t: overUnderTable(activeArr) },
          {
            t1: secondAndThirdOverUnderTable(
              refereeResFotTables(activeArr).t1Ot1U
            ),
          },
          {
            t2: secondAndThirdOverUnderTable(
              refereeResFotTables(activeArr).t2Ot2U
            ),
          },
          { f1: fourthAndFifthTable(refereeResFotTables(activeArr).h1) },
          { f2: fourthAndFifthTable(refereeResFotTables(activeArr).h2) },
        ],
        sums: {
          home_wins: refereeResFotTables(activeArr).winDrawWinTable.home_wins,
          draws: refereeResFotTables(activeArr).winDrawWinTable.draws,
          away_wins: refereeResFotTables(activeArr).winDrawWinTable.away_wins,
        },
      });
      setTableRefereeManual({
        table: [],
        sums: refereeResFotTables(activeArr).verticalTable,
      });
    } else {
      setTableRefereeOdds({
        table: [{ t1: [] }, { t2: [] }, { t: [] }, { f1: [] }, { f2: [] }],
      });
      setTableRefereeManual({ table: [], sums: {} });
    }
  };

  const onSeasonApplyClick = async () => {
    try {
      setLoadingSeason(true);
      await sleep(500);

      const response = await getRefereeSeasonStats(
        seasonFilters?.id,
        seasonFilters?.season_name
      );
      setTableSeason(prepareRefSeasonTable(response.data, seasonsType));
      setSeasonStatus(response.status)
    } catch (error) {
      if (error.response) {
        console.error("Status", error.response.status);
      } else if (error.request) {
        console.error("Error of request:", error.request);
      } else {
        console.error("Simle error is:", error);
      }
    } finally {
      setLoadingSeason(false);
    }
  };

  const onTournamentsApplyClick = async () => {
    try {
      setLoadingTournament(true);
      await sleep(500);

      const response = await getRefereeLeagueStats(
        tournamentsFilters?.id,
        tournamentsFilters?.league_id
      );
      setTableTournaments(prepareTourSeasonTable(response.data, tournamentsType));
      setTournamentStatus(response.status)
    } catch (error) {
      if (error.response) {
        console.error("Status", error.response.status);
      } else if (error.request) {
        console.error("Error of request:", error.request);
      } else {
        console.error("Simle error is:", error);
      }
    } finally {
      setLoadingTournament(false);
    }
  };

  const onRefereeApplyClick = async () => {
    if (Object.keys(timerange).length === 0) {
      try {
        setLoading(true);
        await sleep(500);

        const refereeFixturesResponse = await getRefereeFixtures(
          filters?.stat_type,
          filters?.count,
          refereeData.referee.id,
          filters?.only_with,
          prepareFilters(
            {
              time: filters?.time,
              leagues: filters?.leagues,
              seasons: filters?.seasons,
              against_teams: filters?.against_teams,
            },
            refereeFixturesLeagues
          )
        );
        setTableRefereeFixtures(
          prepareFixturesTable(refereeFixturesResponse.data)
        );
        setRefereeStatus(refereeFixturesResponse.status)
      } catch (error) {
        if (error.response) {
          console.error("Status", error.response.status);
        } else if (error.request) {
          console.error("Error of request:", error.request);
        } else {
          console.error("Simle error is:", error);
        }
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        await sleep(500);

        const refereeFixturesResponse = await getRefereeFixtures(
          refereeData.referee.id,
          prepareFilters({ ...filters, time: timerange })
        );
        setTableRefereeFixtures(
          prepareFixturesTable(refereeFixturesResponse.data)
        );
        setRefereeStatus(refereeFixturesResponse.status)
      } catch (error) {
        if (error.response) {
          console.error("Status", error.response.status);
        } else if (error.request) {
          console.error("Error of request:", error.request);
        } else {
          console.error("Simle error is:", error);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const onLoadClick = async (page, max_page) => {
    if (page <= max_page) {
      const refs = await getOtherReferees(page, id);
      setRefsList((prev) => ({
        ...prev,
        page: refs.page,
        results: prev.results.concat(refs.results),
      }));
    }
  };

  return {
    refsList,
    filters,
    refereeData,
    seasonFilters,
    tableSeason,
    tableTournaments,
    tournamentsFilters,
    timerange,
    tableRefereeFixtures,
    tableRefereeOdds,
    seasonsType,
    tournamentsType,
    tableRefereeManual,
    loading,
    loadingSeason,
    loadingTournament,
    refereeStatus,
    seasonStatus,
    tournamentStatus,
    setSeasonsSums,
    setTournamentsSums,
    setRefereeTableTotalsManual,
    setRanked,
    setOnlyWith,
    setTime,
    setSeasons,
    setAllSeasons,
    setStatType,
    setLeagues,
    setAllLeagues,
    setCount,
    setSeasonSeasons,
    setTournamentsLeagues,
    onSeasonApplyClick,
    onTournamentsApplyClick,
    onRefereeApplyClick,
    onLoadClick,
  };
};
