import { Layout } from "../../layouts/Layout";
import { useLocation } from "react-router-dom";
import { usePrematchStats } from "./hooks/usePrematchStats";
import "../../assets/styles/pre-match.scss";
import { ResultBar } from "../../components/ResultBar/ResultBar";
import { Paginator } from "../../components/Paginator/Paginator";
import { useDispatch, useSelector } from "react-redux";
import { paginatorSelector, setPage } from "../../store/paginatorSlice";
import { useEffect } from "react";
import { LineFilter } from "../../components/LineFilter/LineFilter";
import { NumberFilter } from "../../components/NumberFilter/NumberFilter";
import { TeamRankedFilter } from "../../components/TeamRankedFilter/TeamRankedFilter";
import { Table } from "../../components/Table/Table";
import { HorizontalTable } from "../../components/HorizontalTable/HorizontalTable";
import { RefereeBar } from "./components/RefereeBar/RefereeBar";
import { SelectorFilter } from "../../components/SelectorFilter/SelectorFilter";
import { OthersCard } from "./components/OthersCard/OthersCard";
import { TimeFilter } from "../../components/TimeFilter/TimeFIlter";
import { useStats } from "../League/hooks/useStats";
import { TextAboutMatches } from "../../components/TextAboutMatches/TextAboutMatches";
import { StandingsCards } from "../../components/StandingsCards/StandingsCards";
import { FilterWithPrev } from "../../components/FilterWithPrev/FilterWithPrev";

export const PreMatch = () => {
  const id =
    +useLocation().pathname.split("/")[
      useLocation().pathname.split("/").length - 1
    ];

  const {
    result,
    statistics,
    referee,
    others,
    refereeBarInfo,
    filters,
    h2hFilters,
    refereeFilters,
    refereeSeasonFilters,
    refereeSeasonTable,
    refereeTournamentsFilters,
    refereeTournamentsTable,
    tableRefereeFixtures,
    tableHomeFixtures,
    tableAwayFixtures,
    tableHomeOdds,
    tableAwayOdds,
    tableH2hOdds,
    tableRefereeOdds,
    tableH2h,
    homeFilters,
    awayFilters,
    timerange,
    tableHomeManual,
    tableAwayManual,
    tableH2hManual,
    tableRefereeManual,
    textHome,
    textAway,
    filtersSeasons,
    loading,
    homeFixturesStatus,
    awayFixturesStatus,
    h2hFixturesStatus,
    refereeFixturesStatus,
    setTime,
    setStatType,
    setCount,
    setRefereeTime,
    setRefereeStatType,
    setRefereeCount,
    setRefereeSeasons,
    setAllRefereeSeasons,
    setAllRefereeLeagues,
    setRefereeLeagues,
    setRefereeRanked,
    setRefereeOnlyWith,
    setRefereeSeasonSeasons,
    setRefereeSeasonCategory,
    setRefereeTournaments,
    setRefereeTournamentsCategory,
    setHomePlace,
    setAwayPlace,
    setHomeSeasons,
    setAwaySeasons,
    setAllHomeSeasons,
    setAllAwaySeasons,
    setHomeTeamsRanked,
    setAwayTeamsRanked,
    setHomeLeagues,
    setAwayLeagues,
    setAllHomeLeagues,
    setAllAwayLeagues,
    setHomeTableTotals,
    setHomeTableTotalsManual,
    setAwayTableTotalsManual,
    setAwayTableTotals,
    setRefereeTableTotals,
    setRefereeTableTotalsManual,
    setH2hTime,
    setH2hStatType,
    setH2hSeasons,
    setAllH2hSeasons,
    setH2hCount,
    setAllH2hLeagues,
    setH2hLeagues,
    setH2hTableTotals,
    setH2hTableTotalsManual,
    setHomeCoach,
    setAwayCoach,
    onBothTeamsApplyClick,
    onH2hTeamApplyClick,
    onRefereeApplyClick,
    setH2hAllSeasons,
    setH2hSeason,
  } = usePrematchStats(id);

  const { tableStandings } = useStats(result?.league_id);

  const dispatch = useDispatch();

  const page = useSelector(paginatorSelector);

  const curPage = useLocation().search;
  let pages;
  if (referee !== undefined) {
    pages = [
      "Statistics",
      "H2H",
      "Referee",
      "Odds",
      "Standings",
      "Missing players",
    ];
  } else {
    pages = ["Statistics", "H2H", "Odds", "Standings", "Missing players"];
  }

  useEffect(() => {
    if (curPage) dispatch(setPage(curPage.split("=")[1]));
    else dispatch(setPage("Statistics"));
  }, []);

  return (
    <Layout>
      <div className="container">
        <ResultBar result={result} />
        <RefereeBar data={refereeBarInfo} />
        <p className="title">
          Team statistics, betting odds, history of head-to-head matches, and
          the referee stats
        </p>
        <Paginator pages={pages} />
        {page === "Statistics" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "flex-start",
                marginBottom: 20,
              }}
            >
              <LineFilter
                styles={{
                  borderRadius: "100px 0 0 100px",
                }}
                filters={statistics?.params?.fixtures?.stats?.stats?.main || []}
                setFilter={setStatType}
                activeFilter={filters?.stat_type || ""}
                others={statistics?.params?.fixtures?.stats?.stats?.other || []}
              />
            </div>
            <div style={{ display: "flex", gap: 20, flexWrap: "wrap" }}>
              <NumberFilter
                activeFilter={filters?.count || "20"}
                filters={["5", "10", "15", "20", "30", "40", "50", "100"]}
                setFilter={setCount}
              />
              <TimeFilter
                activeFilter={filters?.time || {}}
                filters={filters?.time || {}}
                setFilter={setTime}
                timerangeValues={timerange}
                withRange={
                  statistics?.params?.fixtures?.stats_minute?.stats?.main.includes(
                    filters?.stat_type
                  ) ||
                  statistics?.params?.fixtures?.stats_minute?.stats?.other.includes(
                    filters?.stat_type
                  )
                }
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                marginTop: 60,
                gap: 20,
              }}
            >
              <div
                style={{ display: "flex", flexDirection: "column", gap: 20 }}
              >
                <p className="team-title">{result?.home?.name}</p>
                <LineFilter
                  activeFilter={homeFilters?.place || {}}
                  filters={["Home", "Away", "All"]}
                  setFilter={setHomePlace}
                />
                <TeamRankedFilter
                  activeFilter={homeFilters?.against_teams || ""}
                  setFilter={setHomeTeamsRanked}
                />
                <LineFilter
                  styles={{
                    borderRadius: "100px",
                  }}
                  filters={homeFilters?.seasons || []}
                  setFilter={setHomeSeasons}
                  activeFilter={homeFilters?.seasons || {}}
                  setAll={setAllHomeSeasons}
                  withAll
                />
                <LineFilter
                  activeFilter={homeFilters?.leagues || {}}
                  filters={homeFilters?.leagues || []}
                  setFilter={setHomeLeagues}
                  setAll={setAllHomeLeagues}
                  withAll
                  isLeagues
                />
                <LineFilter
                  activeFilter={
                    homeFilters?.under_active_coach
                      ? "Under current manager"
                      : "All"
                  }
                  filters={["Under current manager", "All"]}
                  setFilter={setHomeCoach}
                />
              </div>
              <div
                style={{ display: "flex", flexDirection: "column", gap: 20 }}
              >
                <p className="team-title">{result?.away?.name}</p>
                <LineFilter
                  activeFilter={awayFilters?.place || {}}
                  filters={["Home", "Away", "All"]}
                  setFilter={setAwayPlace}
                />
                <TeamRankedFilter
                  activeFilter={awayFilters?.against_teams || ""}
                  setFilter={setAwayTeamsRanked}
                />
                <LineFilter
                  styles={{
                    borderRadius: "100px",
                  }}
                  filters={awayFilters?.seasons || []}
                  setFilter={setAwaySeasons}
                  activeFilter={awayFilters?.seasons || {}}
                  setAll={setAllAwaySeasons}
                  withAll
                />
                <LineFilter
                  activeFilter={awayFilters?.leagues || []}
                  filters={awayFilters?.leagues || []}
                  setFilter={setAwayLeagues}
                  setAll={setAllAwayLeagues}
                  isLeagues
                  withAll
                />
                <LineFilter
                  activeFilter={
                    awayFilters?.under_active_coach
                      ? "Under current manager"
                      : "All"
                  }
                  filters={["Under current manager", "All"]}
                  setFilter={setAwayCoach}
                />
              </div>
            </div>
            <button
              className="stats__button"
              style={{ marginLeft: 0, marginTop: 40, width: "100%" }}
              onClick={onBothTeamsApplyClick}
            >
              Apply
            </button>
            <div style={{ marginTop: 100 }}>
              <div
                style={{ display: "flex", flexDirection: "column", gap: 60 }}
              >
                <div>
                  <p className="team-title" style={{ marginBottom: 20 }}>
                    {result?.home?.name}
                  </p>
                  {!tableHomeFixtures?.table.length && !loading ? (
                    <div className="no-data">
                      <div className="no-data__block">
                        <h4>OOPS!</h4>
                        <p>
                          Unfortunately, don't have data on these statistical
                          metrics. Perhaps we'll include them in the future!
                        </p>
                      </div>
                    </div>
                  ) : homeFixturesStatus === 422 ? (
                    <div className="no-data">
                      <div className="no-data__block">
                        <h4>OOPS!</h4>
                        <p>
                          The filters you've applied don't yield any results.
                          Try a different combination to find the information
                          you're looking for.
                        </p>
                      </div>
                    </div>
                  ) : (
                    <Table
                      headerItems={[
                        { text: "W" },
                        { text: "D" },
                        { text: "L" },
                        { text: "Avg. difference" },
                        { text: "Max" },
                        { text: "Min" },
                        { text: "Max individual total" },
                        { text: "Max total of the opponent" },
                        { text: "Min individual total" },
                        { text: "Min total of the opponent" },
                        { text: "Avg. individual total" },
                        { text: "Avg. total of the opponent" },
                        { text: "Avg. Total" },
                      ]}
                      colsWidth="repeat(13, calc(1290px / 13))"
                      data={tableHomeManual}
                      summaried
                    />
                  )}
                </div>
                <div>
                  <p className="team-title" style={{ marginBottom: 20 }}>
                    {result?.away?.name}
                  </p>
                  {!tableAwayFixtures?.table.length && !loading ? (
                    <div className="no-data">
                      <div className="no-data__block">
                        <h4>OOPS!</h4>
                        <p>
                          Unfortunately, don't have data on these statistical
                          metrics. Perhaps we'll include them in the future!
                        </p>
                      </div>
                    </div>
                  ) : awayFixturesStatus === 422 ? (
                    <div className="no-data">
                      <div className="no-data__block">
                        <h4>OOPS!</h4>
                        <p>
                          The filters you've applied don't yield any results.
                          Try a different combination to find the information
                          you're looking for.
                        </p>
                      </div>
                    </div>
                  ) : (
                    <Table
                      headerItems={[
                        { text: "W" },
                        { text: "D" },
                        { text: "L" },
                        { text: "Avg. difference" },
                        { text: "Max" },
                        { text: "Min" },
                        { text: "Max individual total" },
                        { text: "Max total of the opponent" },
                        { text: "Min individual total" },
                        { text: "Min total of the opponent" },
                        { text: "Avg. individual total" },
                        { text: "Avg. total of the opponent" },
                        { text: "Avg. Total" },
                      ]}
                      colsWidth="repeat(13, calc(1290px / 13))"
                      data={tableAwayManual}
                      summaried
                    />
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: 20,
                  }}
                >
                  <div style={{ width: 629 }}>
                    {(tableHomeFixtures?.table.length ||
                      homeFixturesStatus !== 422) && (
                      <Table
                        headerItems={[
                          { text: "Season" },
                          { text: "Date" },
                          { text: "Home" },
                          { text: "T 1" },
                          { text: "T 2" },
                          { text: "Away" },
                          { text: "T" },
                          { text: "R" },
                          { text: "Score" },
                        ]}
                        colsWidth="30px 120px 70px 110px 40px 40px 110px 30px 30px 49px"
                        data={{ table: tableHomeFixtures?.table }}
                        redCards={tableHomeFixtures?.redCards}
                        showWinner
                        withCheckbox
                        setTableTotals={setHomeTableTotalsManual}
                        teamName={result?.home?.name}
                        teamId={result?.home?.id}
                        page={page}
                        loading={loading}
                      />
                    )}
                  </div>
                  <div style={{ width: 629 }}>
                    {(tableAwayFixtures?.table.length ||
                      awayFixturesStatus === 422) && (
                      <Table
                        headerItems={[
                          { text: "Season" },
                          { text: "Date" },
                          { text: "Home" },
                          { text: "T 1" },
                          { text: "T 2" },
                          { text: "Away" },
                          { text: "T" },
                          { text: "R" },
                          { text: "Score" },
                        ]}
                        colsWidth="30px 120px 70px 110px 40px 40px 110px 30px 30px 49px"
                        data={{ table: tableAwayFixtures?.table }}
                        redCards={tableAwayFixtures?.redCards}
                        showWinner
                        withCheckbox
                        setTableTotals={setAwayTableTotalsManual}
                        teamName={result?.away?.name}
                        teamId={result?.away?.id}
                        page={page}
                        loading={loading}
                      />
                    )}
                  </div>
                </div>
                <div style={{ display: "flex", gap: 20 }}>
                  <div style={{ marginTop: 60, marginBottom: 20 }}>
                    {(tableHomeFixtures?.table.length ||
                      homeFixturesStatus !== 422) && (
                      <>
                        <h2
                          style={{
                            fontSize: 20,
                            fontWeight: 600,
                            marginBottom: 20,
                          }}
                        >
                          Betting tables
                        </h2>
                        <div style={{ display: "flex", marginBottom: 20 }}>
                          <HorizontalTable
                            headerItems={[
                              { text: "Win" },
                              { text: "Draw" },
                              { text: "Lost" },
                            ]}
                            colsWidth="120px 70px"
                            data={{ table: tableHomeOdds?.sums }}
                          />
                        </div>
                        <div style={{ display: "flex", gap: 10 }}>
                          <Table
                            headerItems={[
                              { text: "" },
                              { text: "Over" },
                              { text: "Under" },
                            ]}
                            colsWidth="35px 48px 56px"
                            data={{ table: tableHomeOdds?.table[2]["t"] }}
                            showGreen
                          />
                          <Table
                            headerItems={[
                              { text: "" },
                              { text: "T1O" },
                              { text: "T1U" },
                            ]}
                            colsWidth="35px 48px 56px"
                            data={{ table: tableHomeOdds?.table[0]["t1"] }}
                            showGreen
                          />
                          <Table
                            headerItems={[
                              { text: "" },
                              { text: "T2O" },
                              { text: "T2U" },
                            ]}
                            colsWidth="35px 52px 52px"
                            data={{ table: tableHomeOdds?.table[1]["t2"] }}
                            showGreen
                          />
                          <Table
                            headerItems={[{ text: "" }, { text: "H1" }]}
                            colsWidth="40px 50px"
                            data={{ table: tableHomeOdds?.table[3]["f1"] }}
                            showGreen
                          />
                          <Table
                            headerItems={[{ text: "" }, { text: "H2" }]}
                            colsWidth="40px 50px"
                            data={{ table: tableHomeOdds?.table[4]["f2"] }}
                            showGreen
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div style={{ marginTop: 60, marginBottom: 20 }}>
                    {(tableAwayFixtures?.table.length ||
                      awayFixturesStatus === 422) && (
                      <>
                        <h2
                          style={{
                            fontSize: 20,
                            fontWeight: 600,
                            marginBottom: 20,
                          }}
                        >
                          Betting tables
                        </h2>
                        <div style={{ display: "flex", marginBottom: 20 }}>
                          <HorizontalTable
                            headerItems={[
                              { text: "Win" },
                              { text: "Draw" },
                              { text: "Lost" },
                            ]}
                            colsWidth="120px 70px"
                            data={{ table: tableAwayOdds?.sums }}
                          />
                        </div>
                        <div style={{ display: "flex", gap: 10 }}>
                          <Table
                            headerItems={[
                              { text: "" },
                              { text: "Over" },
                              { text: "Under" },
                            ]}
                            colsWidth="35px 48px 56px"
                            data={{ table: tableAwayOdds?.table[2]["t"] }}
                            showGreen
                          />
                          <Table
                            headerItems={[
                              { text: "" },
                              { text: "T10" },
                              { text: "T1U" },
                            ]}
                            colsWidth="35px 48px 56px"
                            data={{ table: tableAwayOdds?.table[0]["t1"] }}
                            showGreen
                          />
                          <Table
                            headerItems={[
                              { text: "" },
                              { text: "T2O" },
                              { text: "T2U" },
                            ]}
                            colsWidth="35px 52px 52px"
                            data={{ table: tableAwayOdds?.table[1]["t2"] }}
                            showGreen
                          />
                          <Table
                            headerItems={[{ text: "" }, { text: "H1" }]}
                            colsWidth="40px 50px"
                            data={{ table: tableAwayOdds?.table[3]["f1"] }}
                            showGreen
                          />
                          <Table
                            headerItems={[{ text: "" }, { text: "H2" }]}
                            colsWidth="40px 50px"
                            data={{ table: tableAwayOdds?.table[4]["f2"] }}
                            showGreen
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {/* <div>
                  <TextAboutMatches
                    league={tableHomeFixtures?.table[0].season}
                    homeName={result?.home?.name}
                    awayName={result?.away?.name}
                    homeInfo={[textHome]}
                    awayInfo={[textAway]}
                    h2hInfo={tableH2h?.table}
                    referee={[refereeBarInfo]}
                  />
                </div> */}
              </div>
            </div>
          </div>
        )}
        {page === "H2H" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "flex-start",
              }}
            >
              <LineFilter
                styles={{
                  borderRadius: "100px 0 0 100px",
                }}
                filters={statistics?.params?.fixtures?.stats?.stats?.main || []}
                others={statistics?.params?.fixtures?.stats?.stats?.other || []}
                setFilter={setH2hStatType}
                activeFilter={h2hFilters?.stat_type || "Goals"}
              />
            </div>
            <div style={{ display: "flex", gap: 20, flexWrap: "wrap" }}>
              <NumberFilter
                activeFilter={h2hFilters?.count || "20"}
                filters={["5", "10", "15", "20", "30", "40", "50", "100"]}
                setFilter={setH2hCount}
              />
              <TimeFilter
                activeFilter={h2hFilters?.time || {}}
                filters={h2hFilters?.time || {}}
                setFilter={setH2hTime}
                timerangeValues={timerange}
                withRange={
                  statistics?.params?.fixtures?.stats_minute?.stats?.main.includes(
                    h2hFilters?.stat_type
                  ) ||
                  statistics?.params?.fixtures?.stats_minute?.stats?.other.includes(
                    h2hFilters?.stat_type
                  )
                }
              />
            </div>
            <div style={{ display: "flex", gap: 20, flexWrap: "wrap" }}>
              <FilterWithPrev
                styles={{
                  borderLeft: "none",
                  borderRadius: "0px 100px 100px 0px",
                }}
                filters={h2hFilters?.seasons}
                activeFilter={filtersSeasons}
                setFilter={setH2hSeason}
                maxLength={h2hFilters?.seasons.length}
                setAll={setH2hAllSeasons}
                withAll
              />
              <LineFilter
                activeFilter={h2hFilters?.leagues || {}}
                filters={h2hFilters?.leagues || []}
                setFilter={setH2hLeagues}
                setAll={setAllH2hLeagues}
                isLeagues
                withAll
              />
              <button
                className="stats__button"
                style={{ marginLeft: 0, width: 94 }}
                onClick={onH2hTeamApplyClick}
              >
                Apply
              </button>
            </div>

            <div style={{ marginTop: 60 }}>
              <div
                style={{ display: "flex", flexDirection: "column", gap: 40 }}
              >
                {!tableH2h?.table.length && !loading ? (
                  <div className="no-data">
                    <div className="no-data__block">
                      <h4>OOPS!</h4>
                      <p>
                        Unfortunately, don't have data on these statistical
                        metrics. Perhaps we'll include them in the future!
                      </p>
                    </div>
                  </div>
                ) : h2hFixturesStatus === 422 ? (
                  <div className="no-data">
                    <div className="no-data__block">
                      <h4>OOPS!</h4>
                      <p>
                        The filters you've applied don't yield any results. Try
                        a different combination to find the information you're
                        looking for.
                      </p>
                    </div>
                  </div>
                ) : (
                  <>
                    <Table
                      headerItems={[
                        { text: `Wins ${result?.home?.name}` },
                        { text: "Draws" },
                        { text: `Wins ${result?.away?.name}` },
                        { text: "Max. Total" },
                        { text: "Min. Total" },
                        { text: `${result?.home?.name} Total` },
                        { text: `${result?.away?.name} Total` },
                        { text: "Avg. total" },
                      ]}
                      colsWidth="161px 161px 161px 161px 161px 161px 161px 161px"
                      data={tableH2hManual}
                      summaried
                    />
                    <Table
                      headerItems={[
                        { text: "Season" },
                        { text: "Date" },
                        { text: "Home" },
                        { text: "T 1" },
                        { text: "T 2" },
                        { text: "Away" },
                        { text: "T" },
                        { text: "R" },
                        { text: "Score" },
                      ]}
                      colsWidth="32px 190px 70px 350px 40px 40px 350px 30px 30px 162px"
                      data={{ table: tableH2h?.table }}
                      redCards={tableH2h?.redCards}
                      showWinner
                      withCheckbox
                      setTableTotals={setH2hTableTotalsManual}
                      teamName="both"
                      teamId={result?.home?.id}
                      loading={loading}
                    />
                  </>
                )}

                <div style={{ display: "flex", gap: 31, marginTop: 20 }}>
                  <div style={{ marginTop: 20 }}>
                    {tableH2h?.table.length || h2hFixturesStatus === 422 && (
                      <>
                        <h2
                          style={{
                            fontSize: 20,
                            fontWeight: 600,
                            marginBottom: 20,
                          }}
                        >
                          Betting tables
                        </h2>
                        <div style={{ display: "flex", marginBottom: 20 }}>
                          <HorizontalTable
                            headerItems={[
                              { text: `Wins ${result?.home?.name}` },
                              { text: "Draw" },
                              { text: `Wins ${result?.away?.name}` },
                            ]}
                            colsWidth="250px 70px"
                            data={{ table: tableH2hOdds?.sums }}
                          />
                        </div>
                        <div style={{ display: "flex", gap: 10 }}>
                          <Table
                            headerItems={[
                              { text: "" },
                              { text: "Over" },
                              { text: "Under" },
                            ]}
                            colsWidth="40px 98px 98px"
                            data={{ table: tableH2hOdds?.table[0]["t1"] }}
                            showGreen
                          />
                          <Table
                            headerItems={[
                              { text: "" },
                              { text: "T1O" },
                              { text: "T1U" },
                            ]}
                            colsWidth="40px 98px 98px"
                            data={{ table: tableH2hOdds?.table[1]["t2"] }}
                            showGreen
                          />
                          <Table
                            headerItems={[
                              { text: "" },
                              { text: "T2O" },
                              { text: "T2U" },
                            ]}
                            colsWidth="40px 98px 98px"
                            data={{ table: tableH2hOdds?.table[2]["t"] }}
                            showGreen
                          />
                          <Table
                            headerItems={[{ text: "" }, { text: "H1" }]}
                            colsWidth="50px 185px"
                            data={{ table: tableH2hOdds?.table[3]["f1"] }}
                            showGreen
                          />
                          <Table
                            headerItems={[{ text: "" }, { text: "H2" }]}
                            colsWidth="50px 185px"
                            data={{ table: tableH2hOdds?.table[4]["f2"] }}
                            showGreen
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {/* <div>
                  <TextAboutMatches
                    league={tableHomeFixtures?.table[0].season}
                    homeName={result?.home?.name}
                    awayName={result?.away?.name}
                    homeInfo={[textHome]}
                    awayInfo={[textAway]}
                    h2hInfo={tableH2h?.table}
                    referee={refereeBarInfo}
                  />
                </div> */}
              </div>
            </div>
          </div>
        )}
        {page === "Referee" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "flex-start",
              }}
            >
              <LineFilter
                styles={{
                  borderRadius: "100px 0 0 100px",
                }}
                others={referee?.params?.stat_types?.other || []}
                filters={referee?.params?.stat_types?.main || []}
                setFilter={setRefereeStatType}
                activeFilter={
                  refereeFilters?.stat_type ||
                  referee?.params?.default?.stat_type
                }
              />
            </div>
            <div style={{ display: "flex", gap: 20 }}>
              <NumberFilter
                activeFilter={
                  refereeFilters?.count || referee?.params?.default?.count
                }
                filters={["5", "10", "15", "20", "30", "40", "50", "100"]}
                setFilter={setRefereeCount}
              />
              <TimeFilter
                activeFilter={refereeFilters?.time || {}}
                filters={refereeFilters?.time || {}}
                setFilter={setRefereeTime}
                // timerangeValues={timerange}
                // withRange={referee?.params?.stat_types_minute?.main?.includes(
                //   refereeFilters?.stat_type
                // )}
              />
            </div>
            <div style={{ display: "flex", gap: 20 }}>
              <LineFilter
                styles={{
                  borderRadius: "100px",
                }}
                filters={refereeFilters?.seasons || []}
                setFilter={setRefereeSeasons}
                activeFilter={refereeFilters?.seasons || {}}
                setAll={setAllRefereeSeasons}
                withAll
              />
            </div>
            <div>
              <LineFilter
                activeFilter={refereeFilters?.leagues || {}}
                filters={refereeFilters?.leagues || []}
                setFilter={setRefereeLeagues}
                setAll={setAllRefereeLeagues}
                isLeagues
                withAll
              />
            </div>
            <div style={{ display: "flex", gap: 20 }}>
              <LineFilter
                styles={{
                  borderRadius: "100px",
                }}
                filters={[result?.home?.name, result?.away?.name, "All"]}
                setFilter={setRefereeOnlyWith}
                activeFilter={
                  refereeFilters?.only_with?.charAt(0).toUpperCase() +
                    refereeFilters?.only_with?.slice(1) ||
                  referee?.params?.default?.only_with?.charAt(0).toUpperCase() +
                    referee?.params?.default?.only_with?.slice(1)
                }
              />
              <TeamRankedFilter
                activeFilter={refereeFilters?.against_teams || ""}
                setFilter={setRefereeRanked}
              />
              <button
                className="stats__button"
                style={{ marginLeft: 0, width: 94 }}
                onClick={onRefereeApplyClick}
              >
                Apply
              </button>
            </div>
            <div style={{ marginTop: 60 }}>
              <div
                style={{ display: "flex", flexDirection: "column", gap: 40 }}
              >
                {!tableRefereeFixtures?.table.length && !loading ? (
                  <div className="no-data">
                    <div className="no-data__block">
                      <h4>OOPS!</h4>
                      <p>
                        Unfortunately, don't have data on these statistical
                        metrics. Perhaps we'll include them in the future!
                      </p>
                    </div>
                  </div>
                ) : refereeFixturesStatus === 422 ? (
                  <div className="no-data">
                    <div className="no-data__block">
                      <h4>OOPS!</h4>
                      <p>
                        The filters you've applied don't yield any results. Try
                        a different combination to find the information you're
                        looking for.
                      </p>
                    </div>
                  </div>
                ) : (
                  <>
                    <Table
                      headerItems={[
                        { text: "Home wins" },
                        { text: "Draws" },
                        { text: "Away wins" },
                        { text: "Max. Total" },
                        { text: "Min.Total" },
                        { text: "Avg. individual Total of the home team" },
                        { text: "Avg. Individual Total of the away team" },
                        { text: "Avg. total" },
                      ]}
                      colsWidth="161px 161px 161px 161px 161px 161px 161px 161px"
                      data={tableRefereeManual}
                      summaried
                    />
                    <Table
                      headerItems={[
                        { text: "Season" },
                        { text: "Date" },
                        { text: "Home" },
                        { text: "T 1" },
                        { text: "T 2" },
                        { text: "Away" },
                        { text: "T" },
                        { text: "R" },
                        { text: "Score" },
                      ]}
                      colsWidth="32px 190px 70px 350px 40px 40px 350px 30px 30px 162px"
                      data={{ table: tableRefereeFixtures?.table }}
                      redCards={tableRefereeFixtures?.redCards}
                      showWinner
                      withCheckbox
                      setTableTotals={setRefereeTableTotalsManual}
                      loading={loading}
                    />
                  </>
                )}
                <div style={{ display: "flex", gap: 31, flexWrap: "wrap" }}>
                  <div style={{ marginTop: 20 }}>
                    {(tableRefereeFixtures?.table.length ||
                      refereeFixturesStatus === 422) && (
                      <>
                        <h2
                          style={{
                            fontSize: 20,
                            fontWeight: 600,
                            marginBottom: 20,
                          }}
                        >
                          Betting tables
                        </h2>
                        <div style={{ display: "flex", marginBottom: 20 }}>
                          <HorizontalTable
                            headerItems={[
                              { text: "Home wins" },
                              { text: "Draws" },
                              { text: "Away Wins" },
                            ]}
                            colsWidth="120px 70px"
                            data={{ table: tableRefereeOdds?.sums }}
                          />
                        </div>
                        <div style={{ display: "flex", gap: 10 }}>
                          <Table
                            headerItems={[
                              { text: "" },
                              { text: "Over" },
                              { text: "Under" },
                            ]}
                            colsWidth="40px 98px 98px"
                            data={{ table: tableRefereeOdds?.table[0]["t1"] }}
                            showGreen
                          />
                          <Table
                            headerItems={[
                              { text: "" },
                              { text: "T1O" },
                              { text: "T1U" },
                            ]}
                            colsWidth="40px 98px 98px"
                            data={{ table: tableRefereeOdds?.table[1]["t2"] }}
                            showGreen
                          />
                          <Table
                            headerItems={[
                              { text: "" },
                              { text: "T2O" },
                              { text: "T2U" },
                            ]}
                            colsWidth="40px 98px 98px"
                            data={{ table: tableRefereeOdds?.table[2]["t"] }}
                            showGreen
                          />
                          <Table
                            headerItems={[{ text: "" }, { text: "H1" }]}
                            colsWidth="50px 185px"
                            data={{ table: tableRefereeOdds?.table[3]["f1"] }}
                            showGreen
                          />
                          <Table
                            headerItems={[{ text: "" }, { text: "H2" }]}
                            colsWidth="50px 185px"
                            data={{ table: tableRefereeOdds?.table[4]["f2"] }}
                            showGreen
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h2
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                  marginBottom: 20,
                }}
              >
                {refereeBarInfo?.name} - Season's statistics for the referee
              </h2>
              <div style={{ display: "flex", gap: 20, marginBottom: 40 }}>
                <SelectorFilter
                  filters={referee?.params?.seasons || []}
                  setFilter={setRefereeSeasonSeasons}
                  activeFilter={
                    refereeSeasonFilters?.season_name ||
                    referee?.params?.default?.seasons[0]
                  }
                />
                <LineFilter
                  filters={["Average measurements", "Totals"]}
                  activeFilter={refereeSeasonFilters?.category || "Totals"}
                  setFilter={setRefereeSeasonCategory}
                />
              </div>
              <Table
                headerItems={[
                  { text: "Seasons" },
                  { text: "Yellow cards" },
                  { text: "Red cards" },
                  { text: "Penalties" },
                  { text: "Fouls" },
                ]}
                colsWidth="380px 182px 182px 182px 184px"
                data={{
                  table:
                    refereeSeasonFilters?.category === "Totals"
                      ? refereeSeasonTable?.table?.totalLeague
                      : refereeSeasonTable?.table?.averageLeague,
                  sums:
                    refereeSeasonFilters?.category === "Totals"
                      ? refereeSeasonTable?.sums[1]
                      : refereeSeasonTable?.sums[0],
                }}
                summaried
              />
              <h2
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                  marginBottom: 20,
                  marginTop: 100,
                }}
              >
                {refereeBarInfo?.name} - Referee statistics by tournaments
              </h2>
              <div style={{ display: "flex", gap: 20, marginBottom: 40 }}>
                <SelectorFilter
                  filters={
                    referee?.params?.leagues.map((league) => league?.name) || []
                  }
                  setFilter={setRefereeTournaments}
                  activeFilter={
                    refereeTournamentsFilters?.tournament_name ||
                    referee?.params?.default?.leagues[0]?.name ||
                    "England:Premier League"
                  }
                />
                <LineFilter
                  filters={["Average measurements", "Totals"]}
                  activeFilter={refereeTournamentsFilters?.category || "Totals"}
                  setFilter={setRefereeTournamentsCategory}
                />
              </div>
              <Table
                headerItems={[
                  { text: "Leagues" },
                  { text: "Yellow cards" },
                  { text: "Red cards" },
                  { text: "Penalties" },
                  { text: "Fouls" },
                ]}
                colsWidth="380px 182px 182px 182px 184px"
                data={{
                  table:
                    refereeTournamentsFilters?.category === "Totals"
                      ? refereeTournamentsTable?.table?.totalLeague
                      : refereeTournamentsTable?.table?.averageLeague,
                  sums:
                    refereeTournamentsFilters?.category === "Totals"
                      ? refereeTournamentsTable?.sums[1]
                      : refereeTournamentsTable?.sums[0],
                }}
                summaried
              />
              <h2
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                  marginBottom: 30,
                  marginTop: 100,
                }}
              >
                Other referees
              </h2>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: "30px 0",
                }}
              >
                {others?.map((item) => (
                  <OthersCard key={item.id} data={item} />
                ))}
              </div>
            </div>
          </div>
        )}
        {page === "Standings" && (
          <>
            <Table
              headerItems={[
                { text: "№" },
                { text: "Team" },
                { text: "Matches" },
                { text: "W" },
                { text: "D" },
                { text: "L" },
                { text: "Goals" },
                { text: "Points" },
              ]}
              colsWidth="50px 350px 148px 148px 148px 148px 148px 148px"
              data={tableStandings}
            />
            <StandingsCards />
          </>
        )}
      </div>
    </Layout>
  );
};
